.restaurant-profile-footer {
  background-color: white;
  height: 80px;
  width: 100%;

  &__social {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 11px 24px;

    &__icons {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 6px;
    }
  }

  @media (min-width: 768px) {
    height: 100px;

    &__social {
      padding-left: 90px;
      padding-top: 22px;
      padding-bottom: 22px;

      svg {
        width: 35px;
        height: 35px;
      }
    }
  }
}
