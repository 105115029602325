@use "src/styles/fonts";

.socials-container {
    background-color: black;
    padding: 0 26px 20px 26px;

  .socials-label {
    padding-bottom: 10px;
    @include fonts.lato-font(0.875rem, 700);
    line-height: 17px;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    margin-block-start: 0;
    margin-block-end: 0;
    text-transform: uppercase;
  }

  .socials-text {
    padding-bottom: 10px;
    @include fonts.lato-font(1rem, 300);
    line-height: 20px;
    color: #FFFFFF;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  @mixin social-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .instagram-icon {
    @include social-icon;
    background: radial-gradient(99.05% 99.13% at 103.59% -19.85%, #FFDD55 0%, #FFDD55 10%, #FF543E 50%, #C837AB 100%);
  }

  .tiktok-icon {
    @include social-icon;
    background: #FFFFFF;
    border: 1px solid black;
  }

  .snapchat-icon {
    @include social-icon;
    background-color: #FFFC00;
  }

  .x-icon {
    @include social-icon;
    background: #0000;
  }

  .socials-link {
    width: 100%;
    text-decoration: none;
    color: #FFFF;
  }

  .share-box-container {
    padding: 0;
    .share-content {
      height: 55px;
      .share-name {
        text-transform: capitalize;
      }
    }
  }
}
