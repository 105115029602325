@use "src/styles/fonts";

.interactive-content-card-template {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 0 40px;
  width: 100%;

  .interactive-content-card-template-title {
    @include fonts.lato-font(1.75rem, 700);
    line-height: 28px;
    letter-spacing: 0.66px;
    text-align: center;
    text-transform: uppercase;
    margin-block-start: 0;
    margin-block-end: 35px;
  }

  .interactive-content-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    row-gap: 30px;
    max-width: 1350px;

    .interactive-content-card {
      background-color: #fff;
      text-decoration: none;
      color: inherit;
      width: 600px;

      &-link {
        text-decoration: none;
        color: inherit;
        display: flex;
        flex-direction: column;
        row-gap: 14px;

        .interactive-content-card-title {
          @include fonts.lato-font(1.5rem, 700);
          color: #000000;
          text-transform: uppercase;
          line-height: 30px;
          letter-spacing: 0.5px;
          margin-block-start: 0;
          margin-block-end: 0;
        }
      }

      &-image {
        position: relative;
        overflow: hidden;

        img {
          display: block;
          width: 600px;
          height: 454px;
          object-fit: cover;
        }
      }

      &-description {
        display: flex;
        flex-direction: column;
        row-gap: 6px;

        .interactive-content-card-subtitle {
          @include fonts.lato-font(1.25rem, 400);
          color: #000000;
          text-transform: capitalize;
          line-height: 24px;
          letter-spacing: 0.5px;
          margin-block-start: 0;
          margin-block-end: 0;
        }

        .interactive-content-card-text {
          @include fonts.lato-font(0.875rem, 300);
          line-height: 18px;
          letter-spacing: 0;
          color: #000000;
          margin-block-start: 0;
          margin-block-end: 0;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .interactive-content-card-template {
    padding: 0;

    .interactive-content-card-template-title {
      @include fonts.lato-font(1.25rem, 700);
      line-height: 20px;
      align-self: flex-start;
      margin: 0 32px;
    }

    .interactive-content-card-container {
      flex-direction: column;
      row-gap: 25px;
      justify-content: flex-start;
      flex-wrap: nowrap;

      .interactive-content-card {
        width: 374px;

        .interactive-content-card-link {
          row-gap: 6px;

          .interactive-content-card-image {
              img {
                width: 374px;
                height: 215px;
              }
          }

          .interactive-content-card-title {
            @include fonts.lato-font(1rem, 700);
            line-height: 20px;
            letter-spacing: 0.66px;
          }
        }

        .interactive-content-card-description {

          .interactive-content-card-subtitle {
            @include fonts.lato-font(1rem, 400);
            line-height: 16px;
            letter-spacing: 0.66px;
          }

          .interactive-content-card-text {
            @include fonts.lato-font(0.875rem, 300);
            line-height: 16px;
            letter-spacing: 0.66px;
          }
        }
      }
    }
  }
}