.menu-view-container {
  .menu-content-async {
    width: 100%;
    height: calc(100vh - 200px);
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
