@use "src/styles/fonts";

.photo-item-no-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50vw;
  height: 37vh;
  position: relative;
  background-color: black;

  &.widget {
    width: 100%;
    height: 300px;
  }

  .item-photo-placeholder {
    width: 100%;
    z-index: 1;
    height: 37vh;
    background-color: black;
  }

  .item-photo {
    width: 100%;
    object-fit: cover;
    z-index: 1;
    height: 37vh;

    &.widget {
      width: 100%;
      height: 300px;
    }
  }

  .image-gradient {
    width: inherit;
    height: fit-content;
    z-index: 2;
    background: -moz-linear-gradient(rgba(0,0,0,0) 80%, rgba(0,0,0,1)); /* FF3.6+ */
    background: -webkit-linear-gradient(rgba(0,0,0,0) 80%, rgba(0,0,0,1)); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(rgba(0,0,0,0) 80%, rgba(0,0,0,1)); /* Opera 11.10+ */
    background: linear-gradient(rgba(0,0,0,0) 80%, rgba(0,0,0,1)); /* W3C */

    &:after {
      content:'';
      position:absolute;
      left: 0;
      right: 0;
      bottom: 0;
      display: inline-block;
      height: 22%;
      z-index: 2; /* ensures the gradient is above the image but below the text */
      background: -moz-linear-gradient(transparent, rgba(0, 0, 0, 0.75)); /* FF3.6+ */
      background: -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.75)); /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(transparent, rgba(0, 0, 0, 0.75)); /* Opera 11.10+ */
      background: linear-gradient(transparent, rgba(0, 0, 0, 0.75));
    }
  }

  .photo-item-no-text-top-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 15px 10px;
    position: absolute;
    top: 0;
    z-index: 3;

    &.has-images {
      justify-content: space-between;
    }

    .special-tag {
      @include fonts.neue-haas-font(0.688rem, 400);
    }
  }

  .photo-item-no-text-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 6px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;
    text-decoration: none;
    color: white;
    text-align: center;
    padding: 0 5px 13px;

    .photo-item-no-text-name {
      @include fonts.lato-font(0.75rem, 700);
    }

    .photo-item-no-text-price {
      @include fonts.lato-font(1rem, 400);
    }
  }
}
