@use "src/styles/fonts";

.announcement-modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgb(0,0,0, 0.75);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

  &-content {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    padding: 16px 27px;
    width: 75%;
    max-width: 550px;

    @media (min-width: 768px) {
      width: 50%;
    }

    &.has-image {
      padding: 0;
    }

    .announcement-modal-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .close-button.no-image {
        background: initial;
      }

      &.has-image {
        padding: 24px;
        align-items: flex-start;
        border-radius: 6px 6px 0 0;
        height: 196px;
        background-position: center top 50%;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }

    .announcement-modal-header.has-image + .announcement-modal-body {
      padding: 16px 27px;
    }

    .announcement-modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 10px;
      width: 100%;

      .announcement-title {
        @include fonts.lato-font(1.125rem, 700);
        width: 100%;
        line-height: 17px;
        letter-spacing: 1px;
        color: #000000;
        text-align: center;
        word-break: break-word;
        margin-block-start: 0;
        margin-block-end: 0;
      }

      .announcement-description {
        @include fonts.open-sans-font(0.938rem, 300);
        min-height: 50px;
        width: 100%;
        line-height: 20px;
        color: #000000;
        white-space: pre-wrap;
        text-align: left;
        align-self: flex-start;
        word-break: break-word;
        margin-block-start: 0;
        margin-block-end: 0;

        @media (min-width: 768px) {
          min-height: 100px;
        }
      }
    }
  }
}
