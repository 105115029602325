@use "src/styles/fonts";

.drawer-discovery-platforms {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: black;
  z-index: 10;
  position: relative;
  max-width: 550px;

  .share-name {
    color: white;
    margin-top: 5px;
  }

  [data-rsbs-header] {
    padding: 0;
  }

  [data-rsbs-scroll] {
    background-color: black;
  }

  [data-rsbs-overlay] {
    background-color: transparent !important;
    --rsbs-overlay-h: 98vh;
  }

  .discovery-platform-modal-body-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: white;
    background-color: black;
    max-width: 550px;
    padding: 40px 0;

    .share-content {
      height: 65px;
    }

    a {
      text-decoration: none;
      color: inherit;
    }

    img {
      width: 35px;
      height: 35px;
      margin-top: 25px;
    }

    button {
      background-color: transparent;
      border: none;
      padding: 0;
      font: inherit;
      color: inherit;
      cursor: pointer;
    }
  }
}
