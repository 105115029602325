.media-gallery-paginator {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  top: 50%;
  pointer-events: none;

  .media-paginator {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    pointer-events: all;

    width: 30px;
    height: 30px;

    &.should-hide {
      visibility: hidden;
      pointer-events: none;
    }

    &:active, &:hover {
      opacity: 50%;
    }

    &:nth-child(2) {
      & > svg {
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        transform: rotate(-180deg);
      }
    }
  }
}
