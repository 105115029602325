@use "src/styles/fonts";

@mixin active-tab {
  cursor: pointer;
  flex-shrink: 0;
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.5s;
}

.menu-scroll-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 30px;
  background-color: black;
  
  .horizontal-scroll-container {
    position: relative;
    overflow: hidden;

    display: flex;
    align-items: center;
  }

  &.hide-banner {
    // Padding is used to cover space used by PoweredByBanner.jsx
    padding-bottom: 0;
  }

  .menu-change-overlay {
    background: transparent;
    width:      100%;
    height:     100%;
    z-index:    10;
    top:        0;
    left:       0;
    position:   fixed;
  }

  .fixed-top {
    z-index: 4;
    position: sticky;
    top: 0;
    width: 100%;
    /* scrolling colors */
    background-color: black;
    color: white;

    .menu-selection-container-sticky {
      grid-area: menus;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      overflow-x: auto;
      overflow-y: hidden;

      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;

      flex-direction: row;
      padding: 0 8px 6px;
      margin-left: 10px;

      .menu-selection-name-sticky {
        margin: 10px 6px 0;
        @include fonts.lato-font(1.5rem, 700);
        cursor: pointer;
        min-width: fit-content;
        color: white;
        text-decoration: none;
        white-space: nowrap;
        word-break: keep-all;

        &.active {
          color: black;
          background-color: white;
          padding: 2.5px 10px;
        }

        &:active {
          @include active-tab();
        }
      }
    }
  }

  .nav-bar {
    background-color: rgb(0, 0, 0);
    color: white;
    display: flex;
    justify-content: flex-start;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 6px 6px 6px;
    position: relative;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    margin-left: 11px;

    .active {
      @include active-tab();

      display: flex;
      justify-content: center;
      border-bottom: 2px solid white;
    }

    .item-link {
      text-decoration: none;
      height: 100%;
      color: white;
      box-sizing: border-box;
      padding: 0 7.5px 4px 7.5px;
      cursor: pointer;

      .item-name {
        @include fonts.lato-font(0.75rem, 700);
        width: 100%;
        text-align: center;
        white-space: nowrap;
      }
    }
  }

  .bottom-disclaimer {
    padding: 24px;
  }
}

$breakpoint-tablet: 768px;

@media (min-width: $breakpoint-tablet) {
  .menu-scroll-container {
    padding-bottom: 0;

    .nav-bar {
      .item-link > .item-name {
        @include fonts.lato-font(0.938rem, 700);
      }
    }
  }
}
