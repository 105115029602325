.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(240,240,240, 0.6);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 10;
  cursor: initial;

  &-content {
    max-width: 500px;
    background-color: white;
    border-radius: 6px;
    padding: 15px 20px;
  }

  &-header, &-footer {
    padding: 10px;
  }

  &-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    & button {
      width: 100px;
      height: 35px;
      font-size: .75rem;
    }
  }

  &-title {
    margin: 0;
    font-weight: 700;
    font-size: 0.8125rem;
    text-transform: uppercase;
  }

  &-body {
    font-size: 0.75rem;
    padding: 10px;
    letter-spacing: 0.466667px;
    word-break: break-word;
  }
}
