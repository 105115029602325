@use "src/styles/fonts";

.restaurant-profile-header {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  padding-top: 120px;

  @media (max-width: 768px) {
    padding-top: 43px;
  }

  .restaurant-image {
    height: 525px;
    display: inline-block;
    margin-bottom: 22px;
    width: 100%;
    margin-bottom: 22px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .restaurant-info-content {
    padding: 20px 32px;
    text-align: left;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 23px;

    .restaurant-name {
      margin-block-start: 0;
      margin-block-end: 0;
      @include fonts.lato-font(1.25rem, 700);
      text-align: left;
      width: 100%;
      text-transform: uppercase;
    }

    .restaurant-description {
      @include fonts.open-sans-font(0.875rem, 400);
      color: #333;
      line-height: 21px;
      margin-block-start: 0;
      margin-block-end: 0;
      white-space: pre-wrap;
    }

    .cuisine-content {
      align-self: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      row-gap: 3px;
      margin-top: 23px;

      h5 {
        color: #727272;
        @include fonts.lato-font(0.688rem, 700);
        margin-block-start: 0;
        margin-block-end: 0;
        letter-spacing: 1px;
      }

      .restaurant-cuisine {
        color: #000;
        @include fonts.neue-haas-font(1rem, 600);
        line-height: 21px;
        margin-block-start: 0;
        margin-block-end: 0;
      }
    }
  }
}

// Add media queries for responsiveness
@media (min-width: 768px) {
  .restaurant-profile-header {
    .restaurant-info-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      grid-template-areas:
        "name name"
        "description-title gallery-title"
        "description gallery";
      row-gap: 23px;
      column-gap: 30px;
      justify-content: center;
      padding: 25px 90px 0;

      .restaurant-name {
        grid-area: name;
        @include fonts.lato-font(2.25rem, 700);
        text-align: center;
        line-height: 40px;
        letter-spacing: 0.656px;
        margin-bottom: 33px;
      }

      .restaurant-description {
        grid-area: description;
        @include fonts.lato-font(1.125rem, 300);
        line-height: 30px;
        letter-spacing: 0.656px;
        width: 100%;
        white-space: pre-wrap;
      }

      .restaurant-description-title {
        grid-area: description-title;
        @include fonts.lato-font(1.75rem, 700);
        line-height: 40px;
        letter-spacing: 1px;
        margin-block-start: 0;
        margin-block-end: 0;
        text-transform: uppercase;
      }

      .restaurant-gallery-title {
        grid-area: gallery-title;
        @include fonts.lato-font(1.75rem, 700);
        line-height: 40px;
        letter-spacing: 1px;
        margin-block-start: 0;
        margin-block-end: 0;
        padding-left: 150px;
      }

      .gallery-section {
        grid-area: gallery;
        padding: 0 50px;

        .photos-container {
          justify-content: center;
        }

        .explore-photos-link {
          display: none;
        }
      }
    }

    .restaurant-info-content-description-only {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 23px;
      padding: 25px 90px 0;

      .restaurant-name {
        grid-area: name;
        @include fonts.lato-font(2.25rem, 700);
        text-align: center;
        line-height: 40px;
        letter-spacing: 0.656px;
        margin-bottom: 33px;
      }

      .restaurant-description {
        grid-area: description;
        @include fonts.lato-font(1.125rem, 300);
        line-height: 30px;
        letter-spacing: 0.656px;
        width: 100%;
        white-space: pre-wrap;
      }

      .restaurant-description-title {
        grid-area: description-title;
        @include fonts.lato-font(1.75rem, 700);
        line-height: 40px;
        letter-spacing: 1px;
        margin-block-start: 0;
        margin-block-end: 0;
      }
    }

    .restaurant-info-content-gallery-only {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 23px;
      padding: 25px 90px 0;

      .restaurant-name {
        grid-area: name;
        @include fonts.lato-font(2.25rem, 700);
        text-align: center;
        line-height: 40px;
        letter-spacing: 0.656px;
        margin-bottom: 33px;
      }

      .restaurant-gallery-title {
        grid-area: gallery-title;
        @include fonts.lato-font(1.75rem, 700);
        line-height: 40px;
        letter-spacing: 1px;
        margin-block-start: 0;
        margin-block-end: 0;
        padding-left: 0;
      }

      .gallery-section {
        .photos-container {
          justify-content: center;
        }

        .explore-photos-link {
          display: none;
        }
      }
    }
  }
}
