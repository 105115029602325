@use "src/styles/fonts";

.email-submit-modal {
  background-color: black;
  z-index: 10;
  position: relative;
  max-width: 550px;

  [data-rsbs-header] {
    padding: 0;
  }

  [data-rsbs-scroll] {
    background-color: black;
  }

  [data-rsbs-overlay] {
    background-color: transparent !important;
  }

  &-content {
    padding: 23px 26px;

    .email-label {
      @include fonts.lato-font(0.875rem, 700);
      line-height: 17px;
      letter-spacing: 0.5px;
      color: #FFFFFF;
      margin-block-start: 0;
      margin-block-end: 0;
      display: block;
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    .email-submit-copy {
      @include fonts.open-sans-font(1rem, 300);
      line-height: 20px;
      color: #FFFFFF;
      width: 100%;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-bottom: 10px;
    }

    .submit-email-form {
      width: 100%;
      display: block;

      .hook-input {
        margin-bottom: 10px;
      }
    }
  }
}



