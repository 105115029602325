@use "src/styles/fonts";

:export {
  headerHeight: 27.5px;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent; /* make scrollbar transparent */
  display: none;
}

.hidden {
  display: none;
}

.navigation-header-container {
  background-position: center;
  background-size: cover;
  height: 200px;
  width: 100%;

  display: grid;
  grid-template-columns: 1.5fr .5fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 30px 1fr ;
  grid-template-areas:
    "back . icons icons"
    ". . . ."
    "restaurant restaurant restaurant restaurant"
    "menus menus menus menus";

  .back-icon {
    grid-area: back;
    margin-left: 24px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .icons-container {
    grid-area: icons;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    justify-self: flex-end;
    align-self: flex-end;
    width: 100%;
    margin-right: 24px;
    column-gap: 20px;

    .icon-link {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }

    .order-button {
      @include fonts.lato-font(10px, 400);
      line-height: 12px;
      text-align: center;
      color: #FFFFFF;
      background: #05944F;
      border-radius: 1px;
      padding: 2.5px 10px;
      text-transform: uppercase;
      text-decoration: none;
      cursor: pointer;
    }

    .reserve-button {
      @include fonts.lato-font(10px, 400);
      line-height: 12px;
      text-align: center;
      color: #FFFFFF;
      background: #A73333;
      border-radius: 1px;
      padding: 2.5px 10px;
      text-transform: uppercase;
      text-decoration: none;
      cursor: pointer;
    }

    .nav-link {
      @include fonts.lato-font(10px, 400);
      line-height: 12px;
      text-align: center;
      color: #FFFFFF;
      background: transparent;
      padding: 2px 0;
      text-transform: uppercase;
      text-decoration: none;
      cursor: pointer;
    }

    .save-button {
      align-self: flex-start;
    }

    .share-icon {
      min-width: 16px;
      align-self: flex-start;
    }
  }

  #restaurant-title {
    @include fonts.neue-haas-font(1.125rem, 700);
    color: white;
    letter-spacing: 0;
    grid-area: restaurant;
    margin-left: 24px;
    min-width: fit-content;
  }

  .horizontal-scroll-container {
    grid-area: menus;
    position: relative;
    overflow: hidden;

    display: flex;
    align-items: center;

    padding-left: 18px;
    padding-right: 27px;

    &.overflow {
      padding-left: 43px;

      .overflow-arrow.left {
        left: 14px;
      }
    }
  }

  .menu-selection-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;

    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    flex-direction: row;

    & > div {
      align-self: center;
      height: 22px;
      border: 1.5px solid white;
      margin: 0 5px;
    }
  }

  .menu-selection-name {
    margin: 0 8px;
    @include fonts.neue-haas-font(1.5rem, 700);
    cursor: pointer;
    min-width: max-content;
    text-decoration: none;
    color: white;

    &.active {
      color: black;
      background-color: white;
      padding: 2.5px 10px;
    }

    &:active  {
      flex-shrink: 0;
      transform-origin: center center;
      transform: scale(1);
      transition: transform 0.5s;
    }
  }
}

$breakpoint-tablet: 768px;

@media (min-width: $breakpoint-tablet) {
  .navigation-header-container {
    grid-template-rows: 1fr 1fr 37px 1fr ;
    grid-template-areas:
    "back icons icons icons"
    ". icons icons icons"
    "restaurant restaurant restaurant restaurant"
    "menus menus menus menus";

    .icons-container {
      align-self: center;
      width: 100%;
    }
  }
}
