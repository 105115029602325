.vjs-custom-play-button-container {
  &::after {
    content: '';
    position: absolute;
    left: 0; top: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 40%;
    pointer-events: none;
  }
}

.vjs-custom-play-button {
  height: 70px;
  width: 60px;

  background-image: url("../../../assets/svgs/VideoIcons/play-button.svg");

  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  z-index: 2;

  top: 42%;
  left: 50%;
  margin-left: -1.5em;
  position: absolute;
  pointer-events: none;
}

$breakpoint-tablet: 768px;

@media (min-width: $breakpoint-tablet) {
  .vjs-custom-play-button {
    top: 47.5%;
  }
}
