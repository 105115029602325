@use "src/styles/fonts";

.connect-link {
  @include fonts.lato-font(10px, 400);
  line-height: 12px;
  text-align: center;
  color: #FFFFFF;
  background: transparent;
  padding: 2px 0;
  text-transform: uppercase;
  cursor: pointer;
}
