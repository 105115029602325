.menu-item-media-gallery {
  width: 100%;
  height: inherit;

  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;

  display: flex;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;

  .media-gallery-item {
    list-style-type: none;
    position: relative;
    height: inherit;
    scroll-snap-align: center;
    scroll-snap-stop: always;
  }
}
