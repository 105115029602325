@use "src/styles/fonts";

.detailed-menu-item-media-container {
  width: 100%;
  background-color: black;

  &:has(.vjs-has-started.vjs-playing) {
    .menu-item-tag, .pagination-indicator {
      display: none;
    }
  }

  .menu-item-media-container {
    width: 100%;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);

    position: relative;

    &.is-widget {
      height: 538px;
    }

    .menu-item-top-container {
      position: absolute;
      top: 82px;
      padding: 0 20px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      pointer-events: none;

      &.has-media {
        justify-content: space-between;
      }

      .close-button {
        display: none;
        pointer-events: none;
      }

      .menu-item-tag {
        padding: 5px 12px;
        @include fonts.neue-haas-font(1rem, 400);
        color: #FFFFFF;
        text-align: center;
        border-radius: 60px;
        word-break: keep-all;
        white-space: nowrap;
        align-self: flex-end;
      }
    }

    .media-gallery-paginator {
      display: none;
    }

    .menu-item-media-content {
      width: 100%;
      padding: 0 20px 15px 20px;
      display: flex;
      flex-direction: column;
      color: white;

      position: absolute;
      bottom: 0;
      row-gap: 10px;

      .menu-item-calories {
        @include fonts.neue-haas-font(.875rem, 700);
      }

      .menu-item-name {
        line-height: 30px;
        margin-block-start: 0;
        margin-block-end: 0;

        &.large {
          @include fonts.neue-haas-font(1.875rem, 700);
        }

        &.medium {
          @include fonts.neue-haas-font(1.625rem, 700);
        }

        &.small {
          @include fonts.neue-haas-font(1.5rem, 700);
        }
      }
    }
  }

  .menu-item-media-details {
    width: 100%;
    color: white;
    min-height: 60px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .menu-item-price {
      @include fonts.neue-haas-font(1.563rem, 700);
      margin-block-start: 0;
      margin-block-end: 0;
    }

    .menu-item-options-button {
      color: white;
      background-color: #05944F;
      height: 48px;
      width: 165px;
      @include fonts.lato-font(1rem, 400);
      letter-spacing: 0.3pt;
      border: 0;
      border-radius: 0;
    }
  }
}

.menu-item-content-container {
  display: none;
}

$breakpoint-tablet: 768px;

@media (min-width: $breakpoint-tablet) {
  .detailed-menu-item-media-container {
    width: 55%;
    background-color: #FAFAFA;
    z-index: 10;

    .menu-item-media-container {
      height: 100vh;

      &.is-widget {
        height: 950px;
      }

      .menu-item-media-content {
        display: none;
      }

      .menu-item-top-container {
        justify-content: space-between;
        padding: 0 32px;
        top: 32px;
        min-height: 35px;

        .close-button {
          display: flex;
          z-index: 1;
          pointer-events: all;
        }

        .menu-item-tag {
          @include fonts.neue-haas-font(1.063rem, 400);
          height: fit-content;
          padding: 10px 18px;
          line-height: 15px;
          letter-spacing: 0.25px;
          max-height: initial;
          min-height: initial;
          align-self: initial;
        }
      }

      .media-gallery-paginator {
        display: flex;
      }
    }

    .menu-item-media-details {
      display: none;
    }
  }

  .menu-item-content-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 45%;
    padding-top: 8.5em;
    background-color: #FAFAFA;
    position: relative;
    overflow: auto;

    .menu-item-icon-bar-container {
      position: absolute;
      top: 23px;
      padding: 19px 33px 12px 19px;

      background-color: #FAFAFA;
    }

    .menu-item-content-display {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 350px;

      .menu-item-calories {
        margin-top: 12px;
        @include fonts.neue-haas-font(1.125rem, 700);
      }
    }

    .characteristic-container {
      margin-top: 6px;
    }

    .menu-item-name {
      @include fonts.helvetica-neue-font(1.375rem, 700);
      line-height: 27px;
      color: #000000;
      align-self: flex-start;
      margin-bottom: 4px;
      margin-block-start: 0;
      margin-block-end: 0;
    }

    .menu-item-price {
      @include fonts.helvetica-neue-font(0.875rem, 700);
      line-height: 17px;
      align-self: flex-start;
      margin-block-start: 0.5em;
      margin-block-end: 6px;
    }

    .menu-item-description {
      @include fonts.helvetica-neue-font(0.938rem, 400);
      line-height: 22px;
      align-self: flex-start;
      margin-top: 6px;
      padding: 0 5px;
      white-space: pre-wrap;
      margin-block-start: 6px;
      margin-block-end: 0;
    }
  }
}
