@use "src/styles/fonts";

.apple-login-button {
  width: 100%;
  border-radius: 6px;
  min-width: 314px;
  max-width: 366px;
  height: 54px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 10px;

  @include fonts.lato-font(1.25rem, 400);
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.38px;

  background: #FFFFFF;
  color: #000000;
  cursor: pointer;

  span {
    // font size for apple icon svg
    font-size: 23px;
  }
}
