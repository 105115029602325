@use "src/styles/fonts";

.single-card {
  max-width: 1100px;
  display: flex;
  column-gap: 10%;

  .content-card-image {
    flex-grow: 1;
  }
}
