.favorited-restaurant-list-container {
  // bottom padding to account for bottom navigation
  padding-bottom: 66px;

  &.loading-restaurants {
    display: none;
  }

  &.loaded-restaurants {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    padding: 24px;
    row-gap: 24px;
    background-color: white;
    max-width: 800px;
    margin: auto;
  }
}

.restaurant-list-spinner-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 54px;

  .restaurant-list-spinner {
    margin: auto;
  }
}
