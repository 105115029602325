@use "src/styles/fonts";

@mixin dark-theme {
  background-color: black;
  color: white;
}

@mixin light-theme {
  background-color: white;
  color: black;
}

.photo-item-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  position: relative;
  height: 323px;

  &.dark {
    @include dark-theme();
  }

  &.light {
    @include light-theme();
  }

  .item-photo-placeholder {
    height: 323px;
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
    z-index: 1;
    background-color: black;
  }

  .item-photo {
    height: 323px;
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
    z-index: 1;
  }

  .image-gradient {
    width: inherit;
    height: fit-content;
    z-index: 2;

    &:after {
      content:'';
      position:absolute;
      left: 0;
      right: 0;
      bottom: 0;
      display: inline-block;
      height: 20%;
      z-index: 2; /* ensures the gradient is above the image but below the text */
      background: -moz-linear-gradient(transparent, rgba(0, 0, 0, 0.75)); /* FF3.6+ */
      background: -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.75)); /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(transparent, rgba(0, 0, 0, 0.75)); /* Opera 11.10+ */
      background: linear-gradient(transparent, rgba(0, 0, 0, 0.75));
      border-radius: 8px;
    }
  }

  .photo-item-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 5px;
    align-items: flex-start;
    width: 100%;
    padding: 10px 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;

    .item-name {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      @include fonts.helvetica-neue-font(0.875rem, 700);
      max-height: 35px;
      color: white;
      line-height: 17px;
      width: 100%;
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }
}

.photo-item-copy {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 20px 20px 20px;
  width: 100%;
  height: 90px;

  .item-description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    @include fonts.helvetica-neue-font(0.813rem, 400);
    line-height: 14px;
    width: 100%;
    max-height: 65px;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-bottom: 3px;

    &.dark {
      color: white;
    }

    &.light {
      color: #474747;
    }
  }

  .item-price {
    line-height: 17px;
    width: 100%;
    @include fonts.helvetica-neue-font(0.75rem, 400);

    &.dark {
      color: white;
    }

    &.light {
      color: #7D7D7D;
    }
  }
}

$breakpoint-tablet: 768px;

@media (min-width: $breakpoint-tablet) {
  .photo-item-container {
    max-width: 650px;

    .image-gradient {
      border-radius: 0;

      &:after {
        border-radius: 0;
      }
    }

    .item-photo, .item-photo-placeholder {
      max-height: 394.25px !important;
      border-radius: 0;
    }

  }

  .item-price {
    justify-self: flex-start;
    text-align: start;
    white-space: nowrap;
  }

  .item-description {
    align-self: flex-start;
    justify-self: flex-start;
  }
}
