.button {
  width: 182px;
  height: 48px;
  color: white;
  background-color: #217A22;
  font-size: 1.25rem;
  line-height: 24px;
  letter-spacing: 1px;
  font-weight: 400;
  text-align: center;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}
