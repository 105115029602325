@use "src/styles/fonts";

.read-more-text {
  color: white;
  cursor: pointer;
  margin-left: 5px;
  font-weight: bold;
  @include fonts.lato-font(0.9rem, 500);
        letter-spacing: 0.466667px;
}



