@use "src/styles/fonts";

.profile-top-navigation-bar-container {
  width: 100%;
  background-color: black;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 38px;
  padding: 0 65px;
  position: fixed;
  overflow: hidden;
  z-index: 2;
  top: 0;

  .profile-top-navigation-bar-logo {
    width: 305px;
    height: 75.5px;
    padding-left: 5px;
  }

  .profile-top-navigation-bar-navbar {
    width: 100%;

    & > ul {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 37px;

      .profile-top-navigation-bar-link {
        color: white;
        text-align: center;
        @include fonts.lato-font(1rem, 300);
        line-height: 40px;
        letter-spacing: 0.656px;
        text-decoration: none;
        text-transform: uppercase;
        white-space: nowrap;

        &.active {
          @include fonts.lato-font(1rem, 700);
          text-decoration: underline;
          text-underline-offset: 5px;
          text-decoration-thickness: 2px;
        }
      }
    }
  }
}


