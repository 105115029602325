@use "src/styles/fonts";

.modal.delete-account-modal {
  .modal-content {
    border-radius: 5px;
    width: 455px;
    line-height: 22px;
  }

  .modal-header {
    padding: 10px 10px 0 10px;
  }

  .modal-footer {
    padding: 10px 20px;
  }

  .modal-title {
    @include fonts.lato-font(0.8125rem, 700);
    letter-spacing: 1.1375px;
  }

  .modal-message, .modal-warning {
    @include fonts.lato-font(0.875rem, 400);
    letter-spacing: 0.466667px;
  }

  .modal-message {
    color: #6C6C6C;
    margin-bottom: 15px;
  }

  .modal-cancel-button, .modal-confirm-button {
    width: 102px;
    height: 35px;
    color: white;
    letter-spacing: 1.41818px;
    border-radius: 3px;
    line-height: 14px;
    text-align: center;
    @include fonts.lato-font(0.75rem, 400);
    text-transform: uppercase;

    &:hover:not(:disabled) {
      filter: brightness(85%);
    }
  }

  .modal-cancel-button {
    background-color: #7E7E7E;
    margin-right: 12px;
  }

  .modal-confirm-button {
    background-color: #A73333;
  }
}
