@use "src/styles/fonts";

.landing-page {
  height: 100vh;
  width: 100vw;

  // bottom margin to account for bottom navigation
  margin-bottom: 66px;

  .landing-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 35px;
    color: #FFFFFF;
    max-height: 320px;
    max-width: 850px;
    padding: 150px 64px;

    .landing-hero {
      @include fonts.lato-font(3.375rem, 300);
      margin-block: 0;
      line-height: 53px;
      text-transform: uppercase;
      strong {
        @include fonts.lato-font(3.375rem, 400);
        text-transform: uppercase;
      }
    }

    p {
      @include fonts.lato-font(1.063rem, 300);
      margin-block: 0;
    }

    .landing-page-link {
      font-size: 1rem;
      font-weight: 400;
      font-family: var(--lato-400), sans-serif;
      font-style: normal;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.38px;
      background: #05944F;
      color: #FFFFFF;
      border: none;
      cursor: pointer;
      text-decoration: none;
      padding: 12px 32px;
      text-transform: uppercase;

      &:hover {
        opacity: 75%;
      }
    }
  }
}

@media (min-width: 500px) {
  .landing-page {
    .landing-container {
      .landing-hero {
        @include fonts.lato-font(4.375rem, 300);

        strong {
          @include fonts.lato-font(4.375rem, 400);
        }
      }
    }
  }
}
