@use "src/styles/fonts";

.day-time {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 6px;
  flex-wrap: wrap;
  width: 100%;
  @include fonts.open-sans-font(1rem, 400);

  &.bold {
    @include fonts.open-sans-font(1rem, 600);
    color: #000;
  }

  span {
    &.bold {
      @include fonts.open-sans-font(1rem, 600);
      color: #000;
    }
    text-transform: capitalize;
  }
}

