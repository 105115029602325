@use "src/styles/fonts";

.menu-icon-bar {
  width: 100%;
  padding: 15px 23px 0 23px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  background: black;
  column-gap: 20px;

  .back-icon {
    margin-right: auto;
  }

  .account-icon-link {
    height: 15px
  }

  .order-button {
    @include fonts.lato-font(10px, 400);
    line-height: 12px;
    text-align: center;
    color: #FFFFFF;
    background: #05944F;
    border-radius: 1px;
    padding: 2.5px 10px;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
  }

  .reserve-button {
    @include fonts.lato-font(10px, 400);
    line-height: 12px;
    text-align: center;
    color: #FFFFFF;
    background: #A73333;
    border-radius: 1px;
    padding: 2.5px 10px;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
  }

  .nav-link {
    @include fonts.lato-font(10px, 400);
    line-height: 12px;
    text-align: center;
    color: #FFFFFF;
    background: transparent;
    padding: 2px 0;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
  }

}
