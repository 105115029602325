@use "src/styles/fonts";

.content-card-description {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  .content-card-title {
    @include fonts.lato-font(1.5rem, 700);
    line-height: 28px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  .content-card-subtitle {
    @include fonts.lato-font(1.25rem, 400);
    line-height: 24px;
    letter-spacing: 1px;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  .content-card-text {
    @include fonts.lato-font(0.875rem, 300);
    color: #000000;
    line-height: 18px;
    margin-block-start: 0;
    margin-block-end: 0;
    white-space: pre-wrap;
  }
}

/* Media query for mobile */
@media (max-width: 768px) {
  .content-card-description {
    padding: 0 32px;

    .content-card-title {
      @include fonts.lato-font(1rem, 700);
      line-height: 20px;
      letter-spacing: 0.66px;
    }

    .content-card-subtitle {
      @include fonts.lato-font(1rem, 400);
      line-height: 16px;
      letter-spacing: 0.66px;
      margin-block-start: 0;
      margin-block-end: 0;
    }

    .content-card-text {
      @include fonts.lato-font(0.875rem, 300);
      color: #000000;
      line-height: 16px;
      letter-spacing: 0.66px;
      margin-block-start: 0;
      margin-block-end: 0;
      white-space: pre-wrap;
    }
  }
}
