@use "src/styles/fonts";

.pagination-indicator {
  background: rgba(0, 0, 0, 0.5);
  min-height: 19px;
  border-radius: 14px;
  text-align: center;
  padding: 5px 10px;

  &-copy {
    @include fonts.lato-font(1rem, 400);
    line-height: 14px;
    color: #FFFFFF;
    z-index: 5;
  }
}
