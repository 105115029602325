@use "src/styles/mixins";
@use "src/styles/fonts";

.hook-password-input-container {
  position: relative;

  .hook-password-input-view-button {
    position: absolute;
    right: 1rem;
    top: 40px;
    background-color: inherit;
    border: none;

    img {
      height: 1.5rem;
    }
  }
}
