@use "src/styles/fonts";

.restaurant-list-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 420px;
  text-decoration: none;
  color: black;
  margin-bottom: 10px;

  .restaurant-item-photo {
    width: 100%;
    height: 420px;
    object-fit: cover;
  }

  .restaurant-item-photo-wrapper {
    width: 50%;

    .restaurant-item-photo {
      width: 100%;
      height: 420px;
      object-fit: cover;
    }
  }

  .restaurant-content {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 16px;

    .restaurant-item-header {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: left;
      width: 100%;
      row-gap: 8px;
      text-decoration: none;
      color: #000;

      .restaurant-name {
        margin-block: 0;
        @include fonts.neue-haas-font(2.25rem, 700);
        text-decoration: none;
        line-height: 40px;
        letter-spacing: 0.66px;
        text-transform: uppercase;
      }

      .restaurant-info {
        display: flex;
        flex-direction: column;
        width: 100%;

        .restaurant-detailed-info {
          display: flex;
          flex-direction: row;
          column-gap: 6px;
          white-space: nowrap;
          color: #000;
          @include fonts.lato-font(1.25rem, 700);

          & > span {
            color: #000;
            @include fonts.lato-font(1.25rem, 700);
            line-height: 20px;
            white-space: nowrap;
            text-decoration: none;
          }
        }
      }
    }

    .restaurant-info-icons-hours {
      display: flex;
      flex-direction: column;
      justify-content: center;
      justify-self: right;
      margin-top: -62px;
      row-gap: 23px;

      .icons-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        justify-self: left;
        align-self: center;
        width: 100%;
        column-gap: 10px;
        z-index: 2;

        .save-button {
          align-self: flex-start;
          z-index: 2;
        }

        .share-icon {
          min-width: 16px;
          align-self: flex-start;
          z-index: 2;
        }

        .icon-link {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-decoration: none;
        }
      }

      .restaurant-list-item-date {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        color: #000;
        text-decoration: none;

        & > span {
          color: #000;
          @include fonts.lato-font(1.25rem, 700);
          font-size: 20px;
          line-height: 20px;
          white-space: nowrap;
          text-decoration: none;
          text-transform: capitalize;
        }
      }
    }
    .restaurant-description {
      text-decoration: none;
      color: #000;
      p {
        margin-top: 10px;
        @include fonts.neue-haas-font(0.875rem, 300);
        line-height: 18px;
        letter-spacing: 0.66px;
        color: #000;
        text-align: left;
        word-break: break-word;
      }
    }

    .button-container {
      display: flex;
      justify-content: flex-start;
      gap: 10px;

      @media (min-width: 461px) {
        gap: 10px;
      }

      .order-button,
      .reserve-button {
        height: 30px;
        color: white;
        letter-spacing: 1.41818px;
        border-radius: 1px;
        line-height: 14px;
        text-align: center;
        @include fonts.lato-font(0.85rem, 400);
        text-transform: uppercase;

        &:hover:not(:disabled) {
          filter: brightness(85%);
        }
      }

      .order-button {
        background-color: #05944f;
      }

      .reserve-button {
        background-color: #a73333;
      }
    }
  }
  @media (max-width: 430px) {
    margin-bottom: 30px;
  }

  // Media query for mobile screens
  @media (max-width: 855px) {
    flex-direction: column;
    height: 100%;

    .restaurant-item-photo-wrapper {
      width: 100vw;
      height: 241px;
      margin-left: calc(-25px);
      margin-right: calc(-25px);
      margin-bottom: 10px;

      .restaurant-item-photo {
        width: 100vw;
        height: 241px;
      }
    }

    .restaurant-content {
      width: 100%;
      padding: 0;
      order: 2;

      .restaurant-content-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .restaurant-item-header {
        align-items: left;
        row-gap: 5px;

        .restaurant-name {
          @include fonts.neue-haas-font(1.25rem, 700);
          line-height: 24px;
        }

        .restaurant-info {
          text-align: center;

          .restaurant-detailed-info {
            column-gap: 3px;
            @include fonts.lato-font(0.875rem, 700);

            & > span {
              @include fonts.lato-font(0.875rem, 700);
              line-height: 16.8px;
            }
          }
        }
      }

      .restaurant-info-icons-hours {
        margin-top: 2px;
        row-gap: 8px;

        .restaurant-list-item-date {
          & > span {
            @include fonts.lato-font(0.875rem, 700);
            line-height: 16.8px;
          }
        }
      }

      p {
        text-align: left;
        margin-top: 5px;
      }

      .button-container {
        width: 100%;
        justify-content: center;
        margin-left: 5px;
        margin-right: 5px;
        padding-right: 5px;
        margin-bottom: 5px;

        a {
          width: 100%;
        }

        .order-button,
        .reserve-button {
          width: 100%;
          &:hover:not(:disabled) {
            filter: brightness(85%);
          }
        }
      }
    }
  }
}
