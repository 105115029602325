.detailed-menu-item-container {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  overflow: auto;
  background: black;

  &.is-widget {
    position: initial;
  }

  &.is-text {
    background-color: white;
  }
}

$breakpoint-tablet: 768px;

@media (min-width: $breakpoint-tablet) {
  .detailed-menu-item-container {
    display: flex;
    flex-direction: row;
    background-color: #FAFAFA;
    overflow: hidden;
  }
}
