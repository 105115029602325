@use "src/styles/fonts";

@mixin dark-theme {
  background-color: black;
  color: white;
}

@mixin light-theme {
  background-color: white;
  color: black;
}

.column-with-text-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.dark {
    @include dark-theme();
  }

  &.light {
    @include light-theme();
  }

  .column-with-text-sections {
    width: 100%;
    max-width: 430px;

    &.dark {
      @include dark-theme();
    }

    &.light {
      @include light-theme();
    }

    .column-with-text-title {
      padding: 20px 24px 24px 24px;
      align-self: flex-start;

      p {
        @include fonts.neue-haas-font(1rem, 400);
        text-align: left;
        color: inherit;
        padding: 6px 0;
        word-break: break-word;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-top: 5px;
      }

      h2 {
        @include fonts.neue-haas-font(1.25rem, 700);
        margin-block-start: 0;
        margin-block-end: 0;
      }

      &.dark {
        @include dark-theme();
      }

      &.light {
        @include light-theme();
      }
    }

    .column-with-text-container {
      display: grid;
      grid-template-columns: 1fr;
      padding: 8px 8px 20px 8px;
      row-gap: 8px;
      width: 100%;
      max-width: 430px;

      &.dark {
        @include dark-theme();
      }

      &.light {
        @include light-theme();
      }

      .photo-item-container {
        & .duplicate-icon,
        & .play-icon {
          float: left;
          position: absolute;
          left: 0;
          top: 0;
          margin: 15px;
          z-index: 3;
        }
      }

      .photo-item-container .special-tag {
        @include fonts.neue-haas-font(0.688rem, 400);
        padding: 2px 12px;
        float: right;
        position: absolute;
        right: 0;
        top: 0;
        margin: 15px;
        z-index: 3;
      }

      .photo-item-copy {
        @include fonts.helvetica-neue-font(0.875rem, 400);
        row-gap: 3px;
        height: 100px;

        &.no-description {
          height: 50px;
        }

        .item-description {
          font-size: inherit;
          line-height: 17px;
        }

        .item-price {
          font-size: inherit;
        }
      }
    }
  }
}
