@use "src/styles/fonts";

.connect-icon {
  border: 0.6px solid #FFFFFF;
  @include fonts.lato-font(10px, 400);
  line-height: 12px;
  text-align: center;
  color: #FFFFFF;
  background: transparent;
  padding: 2px 5px;
  text-transform: uppercase;
  cursor: pointer;
}
