@use "src/styles/fonts";

.gallery-template-container {
  width: 100%;
  border-color: white;
  position: relative;
  overflow: hidden;
  height: 525px;


  .img-container {
    width: 400px;
    height: 525px;
  }

  .img-container img {
    display: flex;
    margin: 0 auto;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .slick-dots {
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
  }

  .slick-dots li {
    display: inline-block;
    margin: 0 1px;
  }

  .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 10px;
    height: 10px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: grey;
    border-radius: 50%;
    transition: background 0.3s;
  }

  .slick-dots li button:before {
    content: none;
  }

  .slick-dots li.slick-active button {
    background: white;
  }

  .slick-prev, .slick-next {
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slick-prev {
    left: 10px;
  }

  .slick-next {
    right: 10px;
  }

  .slick-prev:before, .slick-next:before {
    font-size: 30px;
    color: black;
    color: white;
  }
}
