@use "src/styles/fonts";

.modal-header-container {
  color: white;
  height: 200px;
  width: 100%;
  background-position: center top 50%;
  background-size: cover;
  background-repeat: no-repeat;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  &-backdrop {
    backdrop-filter: blur(0) brightness(0.4);
    width: 100%;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  &-content {
    height: fit-content;
    padding: 20px 24px;
    width: fit-content;
    display: grid;
    grid-template-columns: 110px 1fr;
    grid-template-rows: 40px 105px;
    row-gap: 28px;
    column-gap: 24px;
    grid-template-areas:
      "close ."
      "image content";

    .close-icon {
      grid-area: close;
      cursor: pointer;

      &:hover {
        opacity: 75%;
      }
    }

    img {
      grid-area: image;
      width: 110px;
      height: 110px;
      object-fit: cover;
      object-position: 20% 50%;
      align-self: flex-end;
      justify-self: flex-end;
    }

    .modal-header-info {
      grid-area: content;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      h1 {
        @include fonts.neue-haas-font(1.25rem, 700);
        letter-spacing: initial;
        line-height: 24px;
        margin-block-start: 0;
        margin-block-end: 0;
        text-align: left;
      }

      p {
        @include fonts.neue-haas-font(0.875rem, 700);
        line-height: 17px;
        color: white;
        margin-block-start: 0;
        margin-block-end: 0;
        text-align: left;
      }
    }
  }
}
