@use "src/styles/fonts";

.profile-navigation-desktop-container {
  width: 100%;
  background-color: white;
  height: 120px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 30px;
  padding: 0 65px;
  position: fixed;
  top: 100px;
  overflow: hidden;
  z-index: 3;

  .profile-navigation-logo {
    height: 75.5px;
    object-fit: cover;
  }

  .profile-navigation-desktop-navbar {
    width: 100%;
    overflow-y: scroll;

    & > ul {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 37px;

      .profile-navigation-desktop-link {
        color: #000;
        text-align: center;
        @include fonts.lato-font(1rem, 300);
        line-height: 40px;
        letter-spacing: 0.656px;
        text-decoration: none;
        text-transform: uppercase;
        white-space: nowrap;

        &.active {
          @include fonts.lato-font(1rem, 400);
          text-decoration: underline;
          text-underline-offset: 5px;
          text-decoration-thickness: 2px;
        }
      }

      .connect-link {
        @include fonts.lato-font(1rem, 300);
        line-height: 40px;
        text-align: center;
        color: #000;
        letter-spacing: 0.656px;
        background: transparent;
        padding: 2px 0;
        text-transform: uppercase;
        cursor: pointer;
      }

      .icons-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        justify-self: left;
        align-self: center;
        width: 100%;
        column-gap: 20px;

        .save-button {
          align-self: flex-start;
        }

        .share-icon {
          min-width: 16px;
          align-self: flex-start;
        }

        .icon-link {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-decoration: none;
        }
      }
    }
  }
}
