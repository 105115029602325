@use "src/styles/fonts";

.restaurant-details {
  padding: 36px 24px;
  background-color: white;
  border-top: none;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .mobile-layout {
    display: flex;
    flex-direction: column;
    row-gap: 22px;

    h2 {
      @include fonts.neue-haas-font(1.25rem, 600);
      margin-bottom: 10px;
      line-height: 20px;
    }

    .details-section {
      display: flex;
      align-items: flex-start;
      border-bottom: 1px solid #ddd;
      width: 100%;
      column-gap: 15px;
      padding-bottom: 20px;

      p {
        @include fonts.open-sans-font(1rem, 400);
        margin: 0;
        line-height: 21px;
      }

      a {
        @include fonts.open-sans-font(1rem, 400);
        color: #000;
        text-decoration: none;
      }

      svg {
        margin-top: 1px;
        width: 16px;
        height: 16px;
      }

      ul {
        @include fonts.open-sans-font(16px, 400);
        color: #757575;
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: 1rem;

        li {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          gap: 90px;

          .bold {
            font-weight: bold;
            color: #000;
          }
        }
      }
    }


  .details-hours-section {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 15px;
    width: 100%;

    ul {
      display: flex;
      flex-direction: column;
      row-gap: 6px;
      margin-block-start: 0;
      margin-block-end: 0;
      padding-inline-start: 0;
    }
  }
}


  .desktop-layout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    text-align: center;
    border-top: none;
    margin-bottom: 20px;
    padding: 50px 25px 20px;
    max-width: 1200px;
    width: 100%;

    h6 {
      @include fonts.lato-font(1.375rem, 600);
      margin-block-start: 0;
      margin-block-end: 8px;
      line-height: 40px;
      letter-spacing: 0.656px;
    }

    .hours-section,
    .location-section,
    .contact-section {
      display: flex;
      flex-direction: column;
      text-align: left;
      min-width: 265px;
    }

    .hours-section {
      display: flex;

      .bold {
        color: #000;
        line-height: 30px;
        letter-spacing: 0.656px;
        @include fonts.lato-font(1.125rem, 400);
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: 1rem;
        align-items: flex-start;

      li {
        color: #000;
        line-height: 30px;
        letter-spacing: 0.656px;
        @include fonts.lato-font(1.125rem, 300);
        gap: 5px;
      }
    }
 }

    a {
      @include fonts.lato-font(1.125rem, 300);
      color: #000;
      text-decoration: none;
      font-size: 1rem;
    }

    p {
      @include fonts.lato-font(1.125rem, 300);
      color: #000;
      margin: 0;
      font-size: 1rem;
    }
  }
}
