@use "src/styles/fonts";

.hook-button-wrapper {
  width: 100%;
  position: relative;
  display: inline-block;

  .hook-button {
    width: 100%;
    height: 30px;
    border-radius: 4px;
    background: #05944F;
    color: #FFFFFF;
    text-align: center;
    letter-spacing: 1px;
    @include fonts.lato-font(0.75rem, 700);
    text-transform: uppercase;
    border: none;
    cursor: pointer;


    &.async {
      color: transparent;
    }

    &:hover:not(:disabled) {
      filter: brightness(85%);
    }

    &:disabled {
      cursor: not-allowed;
      background: #69BE95;
    }
  }

  .spinner-overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
