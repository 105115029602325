.restaurant-profile-navigation {
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: white;
  transition: background-color 0.3s ease;
  z-index: 2;
  padding: 9px 25px;
  justify-content: space-between;
  position: fixed;

  .back-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    margin-left: -10px;
    margin-right: 15px;
  }
  a {
    height: 25px;

    &> .profile-navigation-logo {
      width: 100px;
      height: 25px;
      object-fit: cover;
    }
  }

  .restaurant-profile-navigation__actions {
    display: flex;
    align-items: center;
    margin-left: auto;
    column-gap: 14px;

    @media (min-width: 768px) {
      column-gap: 20px;

    }
  }
}
