@use "src/styles/fonts";

.options-display-container {
  background-color: white;

  .options-display-titles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    row-gap: 4px;
    background-color: #F7F7F7;
    padding: 16px 24px;
    min-height: 81px;
    border-bottom: 1px solid #F7F7F7;

    h3 {
      color: black;
      @include fonts.neue-haas-font(1.25rem, 700);
      margin-block-start: 0;
      margin-block-end: 0;
    }

    h6 {
      @include fonts.open-sans-font(0.875rem, 400);
      color: #545454;
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }
}
