.loading-overlay-container {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  background-color: black;
  pointer-events: none;

  .loading-overlay-content {
    height: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .loading-overlay-spinner {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
