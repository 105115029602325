@use "src/styles/fonts";

.favorites-page {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  background-color: #FFFFFF;
  padding-top: 50px;

  // bottom margin to account for bottom navigation
  margin-bottom: 66px;

  @media (min-width: 768px) {
    top: 100px;
    margin-bottom: 0;
  }

  .favorites-back-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    align-self: start;
    height: 20px;
    margin: 0 24px;
  }

  .favorites-hero {
    @include fonts.neue-haas-font(1.875rem, 700);
    line-height: 30px;
    color: black;
    margin-block: 0;
    margin: 20px 24px 11px 24px;
  }

  .no-favorites-copy {
    @include fonts.open-sans-font(0.875rem, 400);
    color: #333;
    font-size: 14px;
    line-height: 21px;
    margin: 16px 24px 0 24px;
  }

  .favorites-content {
    display: block;

    .favorites-content-header {
      color: #333333;
      @include fonts.open-sans-font(0.75rem, 400);
      margin-left: 24px;
      margin-bottom: 8px;
      white-space: nowrap;
    }
  }

  .favorites-spinner-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 9rem;

    .favorites-spinner {
      margin: auto;
    }
  }

}
