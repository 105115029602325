.dynamic-profile-page {
  width: 100%;
  background-color: #ffffff;
  padding-top: 120px;

  display: flex;
  flex-direction: column;
  row-gap: 46px;

  @media (max-width: 768px) {
    padding-top: 43px;
    row-gap: 22px;
  }
}
