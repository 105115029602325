/* CSS selector used to hide bottom border in bottom sheet header */
[data-rsbs-header] {
  box-shadow: none !important;
  max-width: 550px;
  width: 100%;
  cursor: initial !important;
}

/* CSS selector used to hide drag bar in bottom sheet header */
[data-rsbs-header]:before {
  display: none !important;
  max-width: 550px;
  width: 100%;
}

[data-rsbs-root], [data-rsbs-content], [data-rsbs-scroll] {
  max-width: 550px;
  width: 100%;
}

[data-rsbs-overlay] {
  align-items: center;
}

.custom-bottom-sheet-overlay {
  z-index: 4 !important;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

@media (min-width: 600px) {

  [data-rsbs-overlay] {
    --rsbs-overlay-h: 98vh;
    padding-bottom: 100px;
  }

  [data-rsbs-scroll] {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}
