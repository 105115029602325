@use "src/styles/fonts";

.favorite-success-toast-container {
  --toastify-color-success: #000;
  --toastify-text-color-light: #000;
  --toastify-toast-min-height: 66px;

  border-radius: 6px !important;

  .Toastify__toast-body {
    @include fonts.neue-haas-font(.875rem, 600);
    padding: 0;

    .favorite-success-toast-content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 12px;
      text-decoration: none;
      color: inherit;

      .favorite-success-toast-photo {
        height: 50px;
        width: 41px;
        object-fit: cover;
      }
    }
  }
}


@media (min-width: 768px) {
  .save-success-toast-container {
    .Toastify__toast-body {
      @include fonts.neue-haas-font(1rem, 600);
    }
  }
}
