@use "src/styles/fonts";

.error-copy {
  width: 100%;
  text-align: left;
  color: #E11900;
  white-space: nowrap;
  margin-block: 0;

  @include fonts.lato-font(0.75rem, 400);
}
