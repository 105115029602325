.share-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: black;
  z-index: 10;
  position: relative;
  max-width: 550px;

  [data-rsbs-header] {
    padding: 0;
  }

  [data-rsbs-scroll] {
    background-color: black;
  }

  [data-rsbs-overlay] {
    background-color: transparent !important;
  }

  .twitter-x-icon {
    width: 40px;
    height: 40px;
  }

  .share-modal-body-container {
    display: flex;
    flex-direction: column;
    padding: 40px 0;
    width: 100%;
    color: white;
    background-color: black;
    max-width: 550px;

    .share-content {
      height: 65px;

      svg {
        width: 35px;
        height: 35px;
      }
    }

    img {
      width: 35px;
      height: 35px;
    }

    a {
      text-decoration: none;
      color: inherit;
    }

    button {
      background-color: transparent;
      border: none;
      padding: 0;
      font: inherit;
      color: inherit;
      cursor: pointer;
    }
  }
}
