.embedded-announcement-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 14px 0 14px;

  .embedded-announcement-content {
    width: 100%;
    position: relative;
    padding: 6px 7px;
    max-width: 500px;
    align-self: center;

    .embedded-announcement-image {
      width: inherit;
      height: 250px;
      max-width: 500px;
      border-radius: 7px;
      object-fit: cover;
    }

    .close-button {
      position: absolute;
      left: 0;
      top: 0;
      margin-top: 14px;
      margin-left: 17px;
    }
  }

  @media (min-width: 768px) {
    display: none;
  }
}
