@use "src/styles/fonts";

.share-box-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 30px;
  align-items: center;

  &:hover {
    opacity: 75%;
  }

  .share-content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    white-space: nowrap;
    line-height: 50px;
    height: 70px;
    width: 130px;
    column-gap: 30px;
    align-items: center;

    .share-icon {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .share-name {
      @include fonts.open-sans-font(1rem, 400);
    }

  }

  .select-icon {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
