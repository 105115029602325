.menu-item-icon-bar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 19px 19px 12px 19px;
  transition: 0.3s;

  z-index: 9;
  position: fixed;
  top: 0;

  &.has-background {
    background-color: white;
    color: black;
  }

  .chevron-icon,
  .close-icon {
    margin: 5px;
  }

  .action-icons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    column-gap: 24px;


    .icon-link {
      margin-top: 0;
      text-decoration: none;
      margin-bottom: -5px;
    }

    a {
      text-decoration: none;
    }
  }
}

$breakpoint-tablet: 768px;

@media (min-width: $breakpoint-tablet) {
  .menu-item-icon-bar-container {
    position: absolute;
    top: 23px;
    padding: 19px 33px 12px 19px;

  }
}
