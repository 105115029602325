@use "src/styles/fonts";

.favorited-restaurant-container {
  height: 173px;
  width: 100%;
  position: relative;

  &:hover {
    opacity: 0.85;
    cursor: pointer;
  }

  &.deleted-restaurant {
    opacity: 0.3;
    cursor: not-allowed;
  }

  .favorited-restaurant-photo {
    height: 173px;
    width: 100%;
    object-fit: cover;
    position: absolute;
    z-index: 1;
  }

  .favorited-restaurant-content {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: grid;
    grid-template-rows: auto auto;
    padding: 11px 16px;
    row-gap: 4px;
    width: 100%;
    @include fonts.open-sans-font(0.75rem, 400);
    color: white;

    .favorited-restaurant-name {
      @include fonts.neue-haas-font(1rem, 700);
      color: inherit;
    }

    .favorited-restaurant-details {
      display: grid;
      grid-template-columns: auto auto auto;
      align-items: center;
      justify-content: start;
      gap: 6px;
    }
  }
}
