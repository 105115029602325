.submit-email-form {
  width: 100%;

  .hook-input {
    margin-bottom: 5px;
  }

  fieldset {
    border: none;
    padding: 0;
    margin-inline: 0;
    margin-top: 5px;
  }
}
