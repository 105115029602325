@use "src/styles/fonts";

.grid-no-text-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .grid-no-text-sections {
    width: 100%;
  }

  .grid-no-text-title {
    padding: 20px 24px 24px 24px;

    h2 {
      color: white;
      @include fonts.neue-haas-font(1.25rem, 700);
      margin-block-start: 0;
      margin-block-end: 0;
    }

    p {
      @include fonts.lato-font(1rem, 300);
      text-align: left;
      color: white;
      padding: 6px 0;
      margin-bottom: 5px;
      word-break: break-word;
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }

  .grid-no-text-title.has-message > h2 {
    margin-bottom: 0;
  }

  .grid-no-text-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 20px;

  }
}
