@use "src/styles/fonts";

.onboarding-favorites-message {
  height: 71px;
  width: 100%;
  margin-top: 50px;
  background-color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 25px;
  margin-bottom: 20px;

  p {
    @include fonts.lato-font(1rem, 400);
    letter-spacing: 0.38px;
    color: white;
    line-height: 20px;
    text-wrap: normal;
    width: 250px;
    text-align: left;
    padding: 20px 20px;

    @media (min-width: 768px) {
      width: initial;
    }

    @media (min-width: 300px) {
      width: 100%;
      padding: 10px;
    }
  }

  @media (min-width: 768px) {
    max-width: 366px;
    padding: 20px 10px;
    margin-top: 100px;
  }
}
