@use "src/styles/fonts";

.description-overflow-drawer {
  z-index: 10;
  position: relative;
}

.description-overflow-drawer-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px;
  cursor: pointer;
}

.description-overflow-drawer-content {
  background-color: white;
  padding: 10px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 30px;

  .description-overflow-drawer-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 11px;

    .menu-item-name {
      line-height: 36px;
      margin-bottom: 10px;
      margin-block-start: 0;
      margin-block-end: 0;

      &.large {
        @include fonts.neue-haas-font(1.875rem, 700);
      }

      &.medium {
        @include fonts.neue-haas-font(1.625rem, 700);
      }

      &.small {
        @include fonts.neue-haas-font(1.5rem, 700);
      }
    }

    .price-value {
      @include fonts.neue-haas-font(1.625rem, 700);
      line-height: 17px;
      margin-bottom: 10px;
      margin-block-start: .5em;
      margin-block-end: .5em;
    }

    .description-copy {
      @include fonts.neue-haas-font(1.125rem, 400);
      line-height: 26px;
      white-space: pre-wrap;
    }
  }
}
