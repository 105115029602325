@use "src/styles/fonts";

.favorited-dish-container {
  height: 282px;
  width: 100%;
  position: relative;
  display: block;
  background: black;

  &:hover {
    opacity: 0.85;
    cursor: pointer;
  }

  &.hidden-dish {
    opacity: 0.3;
    cursor: not-allowed;
  }

  .favorited-dish-image-placeholder {
    position: absolute;
    margin: auto;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
  }

  .favorited-dish-photo {
    height: 282px;
    width: 100%;
    object-fit: cover;
    position: absolute;
    z-index: 1;
  }

  .favorited-dish-content {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 13px 24px;
    row-gap: 7px;
    width: 100%;

    .favorited-dish-name {
      @include fonts.lato-font(0.688rem, 700);
      color: white;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 1px;
    }

    .favorited-dish-restaurant {
      @include fonts.lato-font(0.75rem, 700);
      line-height: 17.01px;
      color: white;
      text-align: center;
    }
  }
}
