@mixin lato-font($size: 1rem, $weight: 400, $style: normal) {
  font-size: $size;
  @if $weight == 300 {
    font-weight: 300;
    font-family: var(--lato-300), sans-serif;
    font-style: normal;
  } @else if $weight == 400 {
    font-weight: 400;

    @if $style == italic {
      font-family: var(--lato-400-italic), sans-serif;
      font-style: italic;
    } @else {
      font-family: var(--lato-400), sans-serif;
      font-style: normal;
    }
  } @else {
    font-weight: 700;
    font-family: var(--lato-700), sans-serif;
    font-style: normal;
  }
}

@mixin neue-haas-font($size: 1rem, $weight: 400, $style: normal) {
  font-size: $size;
  @if $weight == 300 {
    font-weight: 300;
    font-family: var(--neue-haas-300), sans-serif;
    font-style: normal;
  } @else if $weight == 400 {
    font-weight: 400;

    @if $style == italic {
      font-family: var(--neue-haas-400-italic), sans-serif;
      font-style: italic;
    } @else {
      font-family: var(--neue-haas-400), sans-serif;
      font-style: normal;
    }
  } @else {
    font-weight: 700;
    font-family: var(--neue-haas-700), sans-serif;
    font-style: normal;
  }
}


@mixin open-sans-font($size: 1rem, $weight: 400, $style: normal) {
  font-size: $size;
  @if $weight == 300 {
    font-weight: 300;
    font-family: var(--open-sans-300), sans-serif;
    font-style: normal;
  } @else if $weight == 400 {
    font-weight: 400;

    @if $style == italic {
      font-family: var(--open-sans-400-italic), sans-serif;
      font-style: italic;
    } @else {
      font-family: var(--open-sans-400), sans-serif;
      font-style: normal;
    }
  } @else {
    font-weight: 700;
    font-family: var(--open-sans-700), sans-serif;
    font-style: normal;
  }
}

@mixin helvetica-neue-font($size: 1rem, $weight: 400, $style: normal) {
  font-size: $size;
  @if $weight == 300 {
    font-weight: 300;
    font-family: var(--helvetica-neue-300), sans-serif;
    font-style: normal;
  } @else if $weight == 400 {
    font-weight: 400;

    @if $style == italic {
      font-family: var(--helvetica-neue-400-italic), sans-serif;
      font-style: italic;
    } @else {
      font-family: var(--helvetica-neue-400), sans-serif;
      font-style: normal;
    }
  } @else {
    font-weight: 700;
    font-family: var(--helvetica-neue-700), sans-serif;
    font-style: normal;
  }
}
