@use "src/styles/fonts";

.gallery-section {
  margin-top: 0;
  background-color: white;
  padding: 0 0 20px 0;

  @media (min-width: 768px) {
    padding: 9px 0 20px 47px;
  }

  .gallery-photos {
    overflow-x: auto;
    padding: 0 0 10px 0;
    white-space: nowrap;
    --webkit-overflow-scrolling: touch;

    .photos-container {
      min-width: 100%;
      display: flex;
      flex-wrap: nowrap;
      gap: 3%;
      overflow-x: auto;
      cursor: pointer;

      @media (min-width: 768px) {
        flex-wrap: wrap;
        min-width: unset; /* Reset the minimum width on desktop */
        row-gap: 43px;
      }

      &:first-child {
        padding-left: 20px;
      }

      &:last-child {
        padding-right: 20px;
      }
    }

    .photo {
      width: 117px;
      height: 117px;
      flex-shrink: 0;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      background: none;

      @media (min-width: 484px) {
        width: 220px;
        height: 140px;
      }

      @media (min-width: 768px) {
        width: 320px;
        height: 200px;
        border-radius: 0;
      }

      @media (min-width: 1024px) {
        width: 425.6px;
        height: 319.2px;
      }

      @media (min-width: 1773px) {
        width: 463px;
        height: 384px;
      }

      .gallery-photo {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-fit: cover;

        @media (min-width: 768px) {
          border-radius: 0;
        }
      }
    }
  }

  .explore-photos-link {
    @include fonts.neue-haas-font(1rem, 600);
    color: #417505;
    display: block;
    text-align: center;
    text-decoration: none;
    background-color: transparent;
    margin-left: auto;
    margin-right: 1%;
  }
}




