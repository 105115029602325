@use "src/styles/fonts";

.characteristic-tag {
  height: 20px;
  width: auto;
  border-radius: 50px;
  padding: 3px 8px;

  @include fonts.neue-haas-font(0.75rem, 700);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
  flex-wrap: nowrap;
  color: #000000;
}
