@use "src/styles/fonts";

.onboarding-page {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 68px;


  @media (min-width: 768px) {
    padding-bottom: 0;
    margin-top: 50px;
  }

  @media (min-width: 344px) {
    position: relative;
  }

  .onboarding-container {
    height: 90vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 38px 24px;
    row-gap: 35px;

    &.show-favorites {
      justify-content: flex-start;
    }

    &.display-form {
      justify-content: flex-start;
    }

    .onboarding-hero-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
      width: 100%;
      max-width: 366px;


      h1 {
        margin-block-end: 0.25em;
      }

      h2 {
        @include fonts.helvetica-neue-font(1.5rem, 400);
        line-height: 26px;
        color: inherit;
        margin-block: 0;
        text-align: center;
      }
    }

    .onboarding-content-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      color: white;
      row-gap: 15px;
      max-width: 366px;
      width: 100%;

      .email-login-button,
      .facebook-login-button {
        width: 100%;
        border-radius: 6px;
        min-width: 314px;
        max-width: 366px;
        height: 54px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        column-gap: 10px;

        @include fonts.lato-font(1.25rem, 400);
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.38px;
      }

      .facebook-login-button {
        background: #3B5998;
        color: inherit;
      }

      .email-login-button {
        background: black;
        text-decoration: none;
        color: inherit;
      }

      .onboarding-policy-copy {
        align-self: flex-start;
        margin-block: 0.5em;
        @include fonts.helvetica-neue-font(1rem, 300);
        line-height: 18px;
        color: inherit;

        a {
          @include fonts.helvetica-neue-font(1rem, 600);
          color: inherit;
          text-decoration: none;
        }
      }
    }

    // TODO: Break out into own component, same as _sign-up.scss
    .sign-up-content-container {
      margin-block-start: 3em;
      width: 100%;
      max-width: 366px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      justify-self: flex-end;
      color: white;
      row-gap: 20px;

      .sign-up-user-info-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        row-gap: 18px;

        fieldset {
          border: none;
          padding: 0;
          margin-inline: 0;
          width: 100%;


          .sign-up-button > .hook-button {
            border-radius: 6px;
            width: 100%;
            height: 48px;

            @include fonts.lato-font(1rem, 400);
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.38px;
          }

        }
      }
    }
  }

  .error-copy {
    align-self: center;
    max-width: 336px;
    white-space: break-spaces;
    margin-block: 1em;
  }
}
