@use "src/styles/fonts";

.menu-item-modifier-group-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  background-color: white;

  .menu-item-modifier-group-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #F7F7F7;
    padding: 16px 24px;
    min-height: 81px;
    border-bottom: 1px solid #F7F7F7;
    width: 100%;

    .menu-item-modifier-group-title {
      color: black;
      @include fonts.neue-haas-font(1.25rem, 700);
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }

  .modifier-list-display {
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
