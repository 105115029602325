@use "src/styles/fonts";

.forgot-password-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;

  // bottom padding to account for bottom navigation
  padding-bottom: 68px;

  @media (min-width: 768px) {
    top: 100px;
    padding-bottom: 0;
  }

  .forgot-password-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 38px 24px;

    .error-copy {
      text-align: center;
      margin-block: 1em;
    }

    .forgot-password-content-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      color: white;
      row-gap: 15px;
      max-width: 366px;
      width: 100%;

      .forgot-password-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        row-gap: 18px;

        fieldset {
          border: none;
          padding: 0;
          margin-inline: 0;
          width: 100%;

          .submit-button > .hook-button {
            border-radius: 6px;
            width: 100%;
            height: 48px;

            @include fonts.lato-font(1rem, 400);
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.38px;
          }
        }
      }

      .forgot-password-success-copy {
        width: 100%;
        max-width: 366px;
        @include fonts.helvetica-neue-font(1.25rem, 400);
        color: #FFFFFF;
        text-align: start;
      }
    }
  }
}
