.vjs-custom-sound-toggle {
  height: 28px;
  width: 28px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  pointer-events: all;

  z-index: 8;
  cursor: pointer;

  top: 82px;
  left: 5%;

  background: rgba(0, 0, 0, 0.25);
  border-radius: 50%;

  &.vs-is-muted {
    .vjs-amplified {
      visibility: hidden;
      height: 0;
      width: 0;
    }
  }

  &.vs-is-amplified {
    .vjs-muted {
      visibility: hidden;
      height: 0;
      width: 0;
    }
  }

  .vjs-muted,
  .vjs-amplified {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 21;
    height: 14px;
    width: 14px;
    pointer-events: all;
  }

  .vjs-muted {
    background-image: url("../../../assets/svgs/VideoIcons/volume-off.svg");
  }

  .vjs-amplified {
    background-image: url("../../../assets/svgs/VideoIcons/volume-high.svg");
  }
}

$breakpoint-tablet: 768px;

@media (min-width: $breakpoint-tablet) {
  .vjs-custom-sound-toggle {
    top: 32px;
    left: initial;
    right: 5%;
  }
}
