@use "src/styles/fonts";

.disclaimer-banner-container {
  @include fonts.open-sans-font(0.875rem, 400);
  color: white;
  min-height: 30px;
  text-align: left;
  padding: 10px 24px;
  background: black;
  white-space: pre-wrap;
}
