@use "src/styles/fonts";

.favorites-tabs {
  width: 100%;
  background-color: white;
  height: 57px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-block: 0;
  padding-inline: 0;
  list-style: none;
  position: sticky;
  top: 0;
  z-index: 7;

  .favorites-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    text-align: center;
    height: inherit;
    @include fonts.neue-haas-font(0.875rem, 600);
    opacity: 0.5;
    border-bottom: 5px solid #E2E2E2;
    transition: border-bottom 0.3s ease;
    cursor: pointer;
    color: black;
    text-decoration: none;

    span {
      min-width: 74px;
    }
  }

  .selected-favorites-tab {
    opacity: 1;
    border-bottom: 5px solid black;
  }
}
