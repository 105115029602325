@use "src/styles/fonts";

.menu-item-modifier-container {
  width: 100%;
  padding: 16px 0;
  border-bottom: 1px solid lightgray;
  background-color: white;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 10px;

  &:last-child {
    border-bottom: none;
  }

  &.has-image {
    display: grid;
    grid-template-columns: 1fr .25fr 90px;
    grid-template-rows: auto;
    grid-template-areas:
        "name . image"
        "description . image"
        "price . image";
    row-gap: 10px;
    grid-column-gap: 6px;
    align-items: center;
  }

  .modifier-name {
    grid-area: name;
    @include fonts.open-sans-font(1rem, 400);
    color: black;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  .modifier-description {
    grid-area: description;
    width: 100%;
    color: #757575;
    @include fonts.open-sans-font(0.875rem, 400);
    margin-block-start: 0;
    margin-block-end: 0;
    white-space: pre-wrap;
  }

  .modifier-price {
    grid-area: price;
    @include fonts.open-sans-font(0.875rem, 400);
    color: #757575;
    width: 100%;
  }

  .modifier-image {
    grid-area: image;
    align-self: flex-start;
    width: 90px;
    height: 120px;
    object-fit: cover;
    border-radius: 8px;
  }
}
