@use "src/styles/fonts";

.profile-navigation-mobile-container {
  width: 100%;
  position: sticky;
  top: 43px;
  z-index: 10;
  background-color: white;

  .profile-navigation-mobile-navbar > ul {
    background-color: white;
    color: #000;
    display: flex;
    justify-content: flex-start;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 8px 6px;
    position: relative;
    margin-left: 20px;
    column-gap: 15px;
    margin-block-start: 0px;
    margin-block-end: 0;

    .profile-navigation-mobile-link,
    .connect-link {
      text-decoration: none;
      color: #000;
      box-sizing: border-box;
      padding: 0 0 4px 0;
      cursor: pointer;
      @include fonts.lato-font(0.75rem, 400);
      text-align: center;
      white-space: nowrap;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      line-height: 17px;

      &.active {
        @include fonts.lato-font(0.75rem, 700);
        display: flex;
        justify-content: center;
        border-bottom: 2px solid black;
      }
    }
  }
}
