@use "src/styles/fonts";

.powered-by-banner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 30px;
  bottom:0;
  background: black;
  color: white;
  @include fonts.neue-haas-font(0.625rem, 400);
  z-index: 99998;

  svg:first-child {
    margin: 0 6px;
  }
}
