@use "src/styles/fonts";

.detailed-menu-item-text-container {
  width: 100%;
  background-color: white;

  display: flex;
  flex-direction: column;
  row-gap: 14px;
  padding: 72px 24px 24px 24px;
  color: black;

  .menu-item-tag-container {
    grid-area: tag;

    .menu-item-tag {
      padding: 5px 12px;
      @include fonts.neue-haas-font(1.06rem, 400);
      color: #FFFFFF;
      text-align: center;
      border-radius: 60px;
      word-break: keep-all;
      white-space: nowrap;
      align-self: flex-end;
    }
  }

  .characteristic-container {
    grid-area: restrictions;

    &.has-tag {
      margin-top: 20px;
    }
  }

  .menu-item-name {
    grid-area: name;
    line-height: 36px;
    margin-block-start: 0;
    margin-block-end: 0;

    &.large {
      @include fonts.neue-haas-font(1.875rem, 700);
    }

    &.medium {
      @include fonts.neue-haas-font(1.625rem, 700);
    }

    &.small {
      @include fonts.neue-haas-font(1.5rem, 700);
    }
  }

  .menu-item-price {
    grid-area: price;
    @include fonts.neue-haas-font(1.625rem, 700);
    line-height: 17px;
    margin-block-start: .5em;
    margin-block-end: .5em;
  }

  .menu-item-calories {
    grid-area: calories;
    @include fonts.neue-haas-font(1.125rem, 700);
  }

  .menu-item-description {
    grid-area: description;
    @include fonts.neue-haas-font(1.125rem, 400);
    line-height: 26px;
    white-space: pre-wrap;
  }
}
