.overflow-arrow {
  display: none;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  border: none;
  z-index: 3;
  cursor: pointer;
  pointer-events: none;
  padding: 0;

  width: 25px;
  height: 25px;

  &:hover {
    opacity: 50%;
  }

  > svg {
    pointer-events: none;
  }

  &.display {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
  }

  &.right {
    right: 0;
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  &.left {
    left: 0;
  }
}
