.data-vjs-player {
  .video-js {
    width: inherit;
    height: inherit;
    position: initial;

    .vjs-tech {
      object-fit: cover;
    }

    .vjs-poster {
      background-size: cover;
      width: inherit;
      height: inherit;
      position: initial;

      &::after {
        content: '';
        position: absolute;
        left: 0; top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
                        rgba(0, 0, 0, 0.6) 2%,
                        rgba(0, 0, 0, 0.3) 15%,
                        rgba(0, 0, 0, .1) 22%,
                        rgba(0, 0, 0, 0) 30%,
                        rgba(0, 0, 0, 0) 63%,
                        rgba(0, 0, 0, 0.025) 65%,
                        rgba(0, 0, 0, 0.05) 67%,
                        rgba(0, 0, 0, 0.1) 69%,
                        rgba(0, 0, 0, 0.15) 71%,
                        rgba(0, 0, 0, 0.2) 73%,
                        rgba(0, 0, 0, 0.35) 78%,
                        rgba(0, 0, 0, 0.45) 82%,
                        rgba(0, 0, 0, .6) 86%,
                        rgba(0, 0, 0, .75) 89%,
                        rgba(0, 0, 0, .9) 94%,
                        rgba(0, 0, 0, 1) 96%
        );
      }
    }

    .vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
      visibility: hidden !important;
    }

    .vjs-control-bar {
      display: none !important;
    }

    .vjs-big-play-button {
      display: none !important;
    }

    &.vjs-has-started.vjs-playing {
      .vjs-custom-play-button-container {
        display: none !important;
      }
    }

    .vjs-custom-sound-toggle {
      display: none !important;
    }
  }

  &:has(.vjs-has-started.vjs-playing) {
    .vjs-custom-sound-toggle {
      display: flex !important;
    }
  }
}
