.menu-item-media-gallery .menu-item-image-gradient {
  height: inherit;

  &::after {
    content: '';
    position: absolute;
    left: 0; top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
                    rgba(0, 0, 0, 0.6) 2%,
                    rgba(0, 0, 0, 0.3) 15%,
                    rgba(0, 0, 0, .1) 22%,
                    rgba(0, 0, 0, 0) 30%,
                    rgba(0, 0, 0, 0) 63%,
                    rgba(0, 0, 0, 0.025) 65%,
                    rgba(0, 0, 0, 0.05) 67%,
                    rgba(0, 0, 0, 0.1) 69%,
                    rgba(0, 0, 0, 0.15) 71%,
                    rgba(0, 0, 0, 0.2) 73%,
                    rgba(0, 0, 0, 0.35) 78%,
                    rgba(0, 0, 0, 0.45) 82%,
                    rgba(0, 0, 0, .6) 86%,
                    rgba(0, 0, 0, .75) 89%,
                    rgba(0, 0, 0, .9) 94%,
                    rgba(0, 0, 0, 1) 96%
    );
  }

  .menu-item-image {
    width: 100vw;
    height: inherit;

    object-fit: cover;
  }
}

$breakpoint-tablet: 768px;

@media (min-width: $breakpoint-tablet) {
  .menu-item-media-gallery .menu-item-image-gradient {
    &::after {
      background: none;
    }

    &.is-widget {
      &::after {
        background: none;
      }
    }

    .menu-item-image {
      width: 55vw;
    }
  }
}

