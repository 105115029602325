.favorited-dish-list-container {
  width: 100%;

  // bottom padding to account for bottom navigation
  padding-bottom: 66px;

  &.loading-dish-items {
    display: none;
  }

  &.loaded-dish-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &.no-dish-items {
    display: block;
    width: 100%;
    padding: 24px;
    max-width: 800px;
    margin: auto;
  }
}

.dish-list-spinner-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 54px;

  .dish-list-spinner {
    margin: auto;
  }
}

