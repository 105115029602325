@use "src/styles/fonts";

.gallery-view-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  z-index: 10;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    cursor: pointer;
    border-radius: 16px 16px 0 0;
    height: 50px;

    .title {
      text-align: center;
      margin: auto;
    }

    h1 {
      @include fonts.open-sans-font(14px, 400);
      color: #000;
      margin: 0;
    }
  }

  @media (min-width: 600px)  {
    [data-rsbs-overlay] {
      padding-bottom: 20px !important;
      max-width: 550px;
      margin: auto;
      --rsbs-overlay-h: 85vh;
      border-radius: 16px 16px 0 0;
    }
  }

  [data-rsbs-header] {
    border-radius: 16px 16px 0 0;
    padding: 0 24px;
  }

  .gallery-view {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    padding: 0 8px 8px 8px;

    .gallery-images {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 0;
      row-gap: 8px;

      .gallery-photo-container {
        width: 100%;
        height: 210px;
        cursor: pointer;
        padding: 0;


        @media (min-width: 600px) {
          height: 280px;
        }

        .gallery-photo {
          width: 100%;
          height: 210px;
          object-fit: cover;

          @media (min-width: 600px) {
            height: 280px;
          }
        }
      }
    }
  }
}
