@use "src/styles/fonts";

.discovery-content-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: auto;

  .swiper {
    width: 100%;
    max-width: 580px;
    height: 100%;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    text-align: center;
    font-size: 18px;
  }

  .nestedSwiper .swiper-slide .discovery-content-item-media {
    position: relative;
    width: 100%;
    height: 100%;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    video {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        rgba(0, 0, 0, 0.6) 2%,
        rgba(0, 0, 0, 0.3) 15%,
        rgba(0, 0, 0, 0.1) 22%,
        rgba(0, 0, 0, 0) 30%,
        rgba(0, 0, 0, 0.1) 69%,
        rgba(0, 0, 0, 0.15) 71%,
        rgba(0, 0, 0, 0.2) 73%,
        rgba(0, 0, 0, 0.35) 78%,
        rgba(0, 0, 0, 0.45) 82%,
        rgba(0, 0, 0, 0.6) 86%,
        rgba(0, 0, 0, 0.75) 89%,
        rgba(0, 0, 0, 0.9) 94%,
        rgba(0, 0, 0, 0.9) 94%,
        rgba(0, 0, 0, 1) 96%
      );
    }
  }

  .nestedSwiper .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .discovery-content-item {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .content-container {
      position: absolute;
      bottom: 75px;
      left: 0%;
      right: 0%;
      padding-left: 5%;
      padding-right: 5%;
      display: flex;
      flex-direction: column;
      z-index: 1;

      .header-icon-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .navigation-link {
          text-decoration: none;
          color: white;
        }

        svg {
          margin: 5px 0;
          width: 22px;
          height: 22px;
        }
      }

      @media (min-width: 461px) {
        bottom: 70px;
      }

      .text-container-image {
        color: white;
        text-align: left;
        margin-bottom: 30px;

        @media (min-width: 461px) {
          margin-bottom: 35px;
        }
      }

      .text-container-video {
        color: white;
        text-align: left;
        margin-bottom: 55px;

        @media (min-width: 461px) {
          margin-bottom: 65px;
        }
      }

      .text-container-video,
      .text-container-image {
        h3,
        h2,
        p {
          margin: 10px 0px 10px;
          padding-right: 13px;
        }

        h3 {
          @include fonts.lato-font(1rem, 700);
          letter-spacing: 1.7375px;
          text-transform: uppercase;
          text-decoration: underline;
          text-decoration-thickness: 0.75px;
          text-underline-offset: 5px;
          margin-bottom: 10px;
        }

        h2 {
          @include fonts.lato-font(1.5rem, 450);
          letter-spacing: 1px;
        }

        p {
          @include fonts.lato-font(0.9rem, 300);
          letter-spacing: 0.466667px;
        }
      }

      .button-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        gap: 10px;

        @media (min-width: 461px) {
          gap: 10px;
        }

        .menu-button {
          background-color: #3563BF;
          color: white;
          height: 30px;
          border-radius: 1px;
          line-height: 14px;
          text-align: center;
          flex: 1;
          @include fonts.lato-font(0.85rem, 400);
          letter-spacing: 1.41818px;
          text-transform: uppercase;

          &:hover:not(:disabled) {
            filter: brightness(85%);
          }
        }


        .order-button,
        .reserve-button {
          flex: 1;
          height: 30px;
          color: white;
          letter-spacing: 1.41818px;
          border-radius: 1px;
          line-height: 14px;
          text-align: center;
          @include fonts.lato-font(0.85rem, 400);
          text-transform: uppercase;

          &:hover:not(:disabled) {
            filter: brightness(85%);
          }
        }

        .order-button-only {
          @extend .order-button;
          position: absolute;
          top: 50%;
          left: 50%;
          height: 30px;
          padding: 0 20px;
          width: 100%;
          @include fonts.lato-font(0.85rem, 400);
          transform: translate(-50%, -100%);
          margin-bottom: 10px;
        }

        .reserve-button-only {
          @extend .reserve-button;
          position: absolute;
          top: 50%;
          left: 50%;
          height: 30px;
          padding: 0 20px;
          width: 100%;
          @include fonts.lato-font(0.85rem, 400);
          transform: translate(-50%, -100%);
          margin-bottom: 10px;
        }

        .order-button {
          background-color: #05944f;
        }

        .reserve-button {
          background-color: #a73333;
        }
      }
    }

    .icon-container {
      position: absolute;
      bottom: 25%;
      right: 3%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      z-index: 1;

      svg {
        margin: 15px 0;
        width: 22px;
        height: 22px;
      }
    }
  }

  .swiper-pagination {
    bottom: auto;
    right: auto;
    color: white;
    z-index: 10;
  }

  .swiper-pagination-fraction {
    position: absolute;
    top: 30px;
    left: -40%;
    color: white;
    font-size: 16px;
    font-weight: bold;
    z-index: 10;

    @media (min-width: 768px) {
      top: 130px;
    }
  }

  .loading {
    margin: 20px 0;
    text-align: center;
    font-size: 1.2rem;
    color: #333;
  }
}
