.close-button {
  border-radius: 50%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.dark {
    background: rgba(0, 0, 0, 0.75);
  }

  &.light {
    background: #FFFFFF;
  }

  &:active, &:hover {
    opacity: 75%;
  }
}
