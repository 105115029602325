.menu-item-video-container {
  width: 100vw;
  height: inherit;

  .menu-item-video {
    object-fit: cover;
    width: 100vw;
    height: inherit;
    cursor: pointer;
  }
}

$breakpoint-tablet: 768px;

@media (min-width: $breakpoint-tablet) {
  .menu-item-media-gallery {
    .menu-item-video-container {
      width: 55vw;
    }
    .menu-item-video {
      width: 55vw;
    }
  }
}
