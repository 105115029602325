@use "src/styles/mixins";
@use "src/styles/fonts";

.hook-input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  .hook-input {
    width: 100%;
    height: 48px;
    background: #F7F7F7;
    border: none;
    padding: 15px 16px;
    line-height: 19px;
    @include fonts.lato-font(1rem, 400);
    color: black;

    &::placeholder {
      color: #757575;
    }

    &:focus-visible {
      outline: 2px solid black;
    }

    &:invalid, &[aria-invalid="true"] {
      background: #FFEFED;
      outline: 2px solid #F1998E;
    }

    &:disabled {
      cursor: not-allowed;
      color: #AFAFAF;
    }
  }

  .hook-input-label {
    line-height: 20px;
    @include fonts.lato-font(1rem, 400);
    color: white;

    &.invisible {
      @include mixins.visually-hidden;
    }
  }

  .hook-input-hint {
    @include fonts.lato-font(0.875rem, 400);

    line-height: 17px;
    color: #808080;

    &.invisible {
      @include mixins.visually-hidden;
    }
  }

  .hook-input-error {
    @include fonts.lato-font(0.875rem, 400);

    line-height: 17px;
    color: #E11900;

    &.invisible {
      @include mixins.visually-hidden;
    }
  }
}
