.play-icon {
  height: 18px;
  width: 20px;

  background-image: url("../../VideoIcons/play-button.svg");

  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
