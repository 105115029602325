.text-area-container {
  textarea {
    width: 100%;
    padding: 4px;
    font-size: 12px;
  }

  .error-message {
    width: 100%;
    text-align: left;
    color: #A73333;
    margin-top: 6px;
    font-weight: 400;
    font-size: 14px;
    white-space: nowrap;
  }
}
