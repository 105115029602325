@use "src/styles/fonts";

.settings-page {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;

  // bottom margin to account for bottom navigation
  margin-bottom: 66px;

  @media (min-width: 768px) {
    top: 100px;
    margin-bottom: 0;
  }

  background-color: #FFFFFF;

  .settings-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 50px 24px;

    .settings-header-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
      width: 100%;
      row-gap: 35px;
      max-width: 375px;


      .settings-page-back-button {
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        align-self: start;
        height: 20px;
      }

      .settings-hero-header {
        margin-block: 0;
        @include fonts.neue-haas-font(1.875rem, 700);
        line-height: 30px;
        color: black;
        align-self: flex-start;
      }

      .user-info-container {
        width: 100%;
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto;
        grid-template-areas:
        "name image"
        "phone image"
        "email image";
        row-gap: 4px;
        column-gap: 30px;
        align-items: flex-start;

        .user-full-name {
          grid-area: name;
          @include fonts.neue-haas-font(1.25rem, 700);
          line-height: 24px;
          color: black;
          justify-self: flex-start;

          &.no-phone {
            grid-area: 1 / 1 / span 2 / 2;
          }
        }

        .user-phone {
          grid-area: phone;
          @include fonts.open-sans-font(.875rem, 300);
          line-height: 21px;
          color: #757575;
          justify-self: flex-start;
        }

        .user-email {
          grid-area: email;
          @include fonts.open-sans-font(.875rem, 300);
          line-height: 21px;
          color: #757575;
          justify-self: flex-start;

          &.no-phone {
            grid-area: 2 / 1 / span 2 / 2;
          }
        }

        .user-image {
          grid-area: image;
          object-fit: cover;
          height: 78px;
          width: 78px;
          border-radius: 50%;
          justify-self: flex-start;
        }
      }

      h2 {
        @include fonts.lato-font(1.5rem, 400);
        line-height: 26px;
        color: inherit;
        margin-block: 0;
        text-align: center;
      }
    }

    .settings-content-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      color: black;
      background-color: inherit;
      width: 100%;
      max-width: 375px;
      padding-inline-start: 0;

      .settings-page-link {
        width: 100%;
        list-style-type: none;
        height: 68px;

        &:first-child > a {
          border-top: 1px solid #E2E2E2;
        }

        a, .sign-out-button {
          background-color: white;
          color: black;
          width: 100%;
          height: 66px;
          @include fonts.open-sans-font(1rem, 400);
          line-height: 24px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          text-decoration: none;
          border-bottom: 1px solid #E2E2E2;
          padding: 0 0 0 1px;
          letter-spacing: initial;

          .chevron-icon {
            -webkit-transform: rotate(-180deg);
            -moz-transform: rotate(-180deg);
            -o-transform: rotate(-180deg);
            -ms-transform: rotate(-180deg);
            transform: rotate(-180deg);
          }

          &:hover, &:focus {
            background-color: #F7F7F7;
          }
        }
      }
    }

    .settings-spinner {
      margin: auto;
    }
  }
}
