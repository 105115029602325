@use "src/styles/fonts";

.copy-template-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  padding: 0 32px;
  row-gap: 42px;

  .copy-template-title {
    @include fonts.lato-font(1.75rem, 700);
    line-height: 20px;
    letter-spacing: 0.66px;
    text-transform: uppercase;
    text-align: center;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  .copy-template-content {
    @include fonts.lato-font(1rem, 300);
    color: #000000;
    line-height: 18px;
    margin-block-start: 0;
    margin-block-end: 0;
    white-space: pre-wrap;
    max-width: 1238px;
    letter-spacing: 0.66px;
  }

    /* Media query for mobile */
  @media (max-width: 768px) {
    padding: 0 32px;

    row-gap: 22px;

    .copy-template-title {
      @include fonts.lato-font(1.25rem, 700);
      text-align: left;
      width: 100%;
    }

    .copy-template-content {
      @include fonts.lato-font(0.875rem, 300);
      line-height: 16px;
      align-self: flex-start;
    }
  }
}
