@use "src/styles/fonts";

.sign-up-icon {
  @include fonts.lato-font(10px, 400);
  line-height: 12px;
  text-align: center;
  background: transparent;
  padding: 2px 5px;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;

  &.white {
    border: 0.6px solid #FFFFFF;
    color: #FFFFFF;
  }

  &.black {
    border: 1px solid #000000;
    color: #000000;
  }
}
