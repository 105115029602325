@use "src/styles/fonts";

.ancillary-list-container {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    width: 100%;
    text-decoration: none;
    border-bottom: 1px solid lightgray;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
  }

  .ancillary-text-item {
    width: 100%;
    padding: 16px 0;
    border-bottom: 1px solid lightgray;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &.has-price {
      justify-content: space-between;
    }

    &:last-child {
      border-bottom: none;
    }

    .ancillary-name {
      @include fonts.open-sans-font(1rem, 400);
      color: black;
    }

    .ancillary-price {
      @include fonts.open-sans-font(0.875rem, 400);
      color: #757575;
    }
  }

  .ancillary-image-item {
    width: 100%;
    padding: 16px 0;
    border-bottom: 1px solid lightgray;

    &:last-child {
      border-bottom: none;
    }

    &.clickable:hover {
      opacity: 0.5;
      cursor: pointer;
    }

    &.has-image {
      display: grid;
      grid-template-columns: 1fr .25fr 90px;
      grid-template-rows: auto;
      grid-template-areas:
        "name . image"
        "description . image"
        "price . image";
      row-gap: 5px;
      grid-column-gap: 6px;
      align-items: center;
    }

    &.no-image {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      row-gap: 10px;
    }

    .ancillary-name {
      grid-area: name;
      @include fonts.open-sans-font(1rem, 400);
      color: black;
      margin-block-start: 0;
      margin-block-end: 0;
    }

    .ancillary-description {
      grid-area: description;
      color: #757575;
      @include fonts.open-sans-font(0.875rem, 400);
      margin-block-start: 0;
      margin-block-end: 0;
      white-space: pre-wrap;
      max-width: 320px;
    }

    .ancillary-price {
      grid-area: price;
      @include fonts.open-sans-font(0.875rem, 400);
      color: #757575;

      &.has-image {
        margin-top: 8px;
      }
    }

    .ancillary-image {
      grid-area: image;
      width: 90px;
      height: 120px;
      object-fit: cover;
      border-radius: 8px;
      align-self: flex-start;
    }
  }
}
