.pause-icon {
  height: 20px;
  width: 20px;

  background-image: url("../../VideoIcons/pause-button.svg");

  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
