.restaurant-profile-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  // padding margin to account for bottom navigation
  padding-bottom: 66px;

  @media (min-width: 768px) {
    top: 100px;
    padding-bottom: 0;
  }
}
