@use "src/styles/fonts";

.restaurant-list-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;

  // bottom padding to account for bottom navigation
  padding-bottom: 68px;


  @media (min-width: 768px) {
    top: 100px;
    padding-bottom: 0;
  }

  .restaurant-list-top-bar {
    white-space: nowrap;
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
    top: 0;
    background-color: #FFFFFF;
    z-index: 1000;
    padding: 20px 0;
    text-align: center;
    transition: 0.3s;
    width: 100%;

    &.scrolled {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
  }

  .restaurant-list-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: inherit;
    padding: 25px;
    row-gap: 16px;
    margin-bottom: 35px;

    .restaurant-list-title {
      color: #000;
      @include fonts.neue-haas-font(2.125rem, 700);
      line-height: normal;
      margin-block: 0;
    }

    .restaurant-list-copy {
      color: #333;
      @include fonts.open-sans-font(0.875rem, 400);
      line-height: 21px;
      margin-block: 0;
      margin-bottom: 7px;
    }

    .restaurant-list-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      row-gap: 40px;

      @media (max-width: 767px) {
        row-gap: 2px;
      }
    }
  }
}
