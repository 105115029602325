@use "src/styles/fonts";

.carousel-view-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  z-index: 10;

  [data-rsbs-header] {
    padding: 0;
    background-color: black;
    max-width: unset;
  }

  [data-rsbs-scroll] {
    background-color: black;
    max-width: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  [data-rsbs-content] {
    max-width: unset;
  }

  [data-rsbs-overlay] {
    background-color: transparent !important;
      --rsbs-overlay-h: 100vh;
      padding-bottom: 0;
  }
}

.header-carousel {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 25px;
    margin-top: 0;
    cursor: pointer;
    color: white;

    h1 {
      @include fonts.open-sans-font(14px, 400);
      color: white;
      margin: 0;
    }
}
.title-gallery {
  flex: 1;
  text-align: center;
  margin: 0;
}

.carousel-view {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
}

.carousel-photo-container {
  width: 100%;
  height: 100%;
}

.carousel-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Media query for larger screens */
@media (min-width: 600px)  {
  .gallery-images {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
  }

  .carousel-photo-container {
    width: 100%;
    margin-bottom: 5px;
  }

  .carousel-view-container {
    background-color: black;
    z-index: 10;
    position: relative;
    max-width: 750px;

    [data-rsbs-header] {
      padding: 0;
      background-color: black;
      border-radius: 16px 16px 0 0;
      max-width: 550px;
    }

    [data-rsbs-scroll] {
      background-color: black;
      max-width: 550px;

    }

    [data-rsbs-content] {
      max-width: 550px;
    }

    [data-rsbs-overlay] {
      background-color: transparent !important;
      --rsbs-overlay-h: 95vh;
      padding-bottom: 50px;
    }

    .header-carousel {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      cursor: pointer;
      background-color: black;
      border-radius: 16px 16px 0 0;


      h1 {
        color: white;
        margin: 10px 0 0 0;
      }
  }
 }
}
