@use "src/styles/fonts";

.content-card-template {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 0 40px;
  width: 100%;
  row-gap: 35px;

  .content-card-template-title {
    @include fonts.lato-font(1.75rem, 700);
    line-height: 28px;
    letter-spacing: 0.66px;
    text-align: center;
    text-transform: uppercase;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  .content-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    max-width: 1350px;
    column-gap: 3%;
    row-gap: 50px;

    .content-card {
      display: flex;
      flex-direction: column;
      row-gap: 26px;

      .content-card-image {
        width: inherit;
        height: 575px;
        object-fit: cover;
      }
    }

    .single-card {
      .content-card-image {
        width: 400px;
        height: 500px;
        object-fit: cover;
      }
    }
  }
}

/* Media query for mobile */
@media (max-width: 768px) {
  .content-card-template {
    padding: 22px 0;

    .content-card-template-title {
      @include fonts.lato-font(1.25rem, 700);
      line-height: 20px;
      align-self: flex-start;
      margin: 0 32px;
    }

    .content-card-image {
      height: 500px;
      object-fit: cover;
      object-position: 10% 20%;
    }
  }
}
