@use "src/styles/fonts";

.account-profile-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;

  background-color: #FFFFFF;

  // bottom margin to account for bottom navigation
  margin-bottom: 66px;

  @media (min-width: 768px) {
    top: 100px;
    margin-bottom: 0;
  }

  .account-profile-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 50px 24px;

    .error-copy {
      text-align: center;
      margin-block: 0.5em;
    }

    .account-profile-content-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      background-color: inherit;
      width: 100%;
      max-width: 375px;
      padding-inline-start: 0;

      .account-profile-back-button {
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        align-self: start;
        height: 20px;
        margin-bottom: 20px;
      }

      .account-profile-title {
        @include fonts.neue-haas-font(20px, 700);
        line-height: 24px;
        color: black;
      }

      .account-profile-form {
        width: 100%;
        margin-bottom: 20px;

        display: grid;
        grid-template-columns: 0.5fr 0.5fr;
        grid-template-rows: auto;
        grid-template-areas:
        "first last"
        "phone phone"
        "email email"
        "button button";
        row-gap: 18px;
        column-gap: 6px;

        .first-name-input {
          grid-area: first;
        }

        .last-name-input {
          grid-area: last;
        }

        .phone-input{
          grid-area: phone;
        }

        .email-input {
          grid-area: email;
        }

        fieldset {
          border: none;
          padding: 0;
          margin-inline: 0;
          width: 100%;
          grid-area: button;

          .submit-button > .hook-button {
            border-radius: 6px;
            width: 100%;
            height: 48px;

            @include fonts.lato-font(1rem, 400);
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.38px;
          }
        }
      }
    }

    .delete-account-button {
      margin: auto;
      width: 250px;
      height: 48px;
      min-height: 48px;
      border-radius: 4px;
      background: #E85C4A;
      color: #FFFFFF;
      text-align: center;
      letter-spacing: 0.38px;
      @include fonts.lato-font(1rem, 400);
      line-height: 24px;
      text-transform: uppercase;
      border: none;
      cursor: pointer;

      &.async {
        color: transparent;
      }

      &:hover:not(:disabled) {
        filter: brightness(85%);
      }
    }

    .account-profile-spinner {
      margin: auto;
    }
  }
}
