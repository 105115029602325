@use "src/styles/fonts";

@mixin dark-theme {
  background-color: black;
  color: white;
}

@mixin light-theme {
  background-color: white;
  color: black;
}

.desktop-grid-with-text-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.dark {
    @include dark-theme();
  }

  &.light {
    @include light-theme();
  }

  .desktop-grid-with-text-sections {
    width: 100%;
    max-width: 1680px;

    &.dark {
      @include dark-theme();
    }

    &.light {
      @include light-theme();
    }

    .desktop-grid-with-text-title {
      padding: 40px 24px 24px 24px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      align-self: flex-start;

      h2 {
        @include fonts.neue-haas-font(1.25rem, 700);
        margin-block-start: 0;
        margin-block-end: 0;
      }

      p {
        @include fonts.neue-haas-font(1rem, 400);
        text-align: left;
        color: inherit;
        line-height: 20px;
        word-break: break-word;
        margin-block-start: 0;
        margin-block-end: 0;
      }

      &.dark {
        @include dark-theme();
      }

      &.light {
        @include light-theme();
      }
    }

    .desktop-grid-with-text-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      padding: 8px 20px 0 20px;
      column-gap: 21.5px;
      row-gap: 8px;
      width: 100%;

      &.dark {
        @include dark-theme();
      }

      &.light {
        @include light-theme();
      }

      .duplicate-icon,
      .play-icon {
        float: left;
        position: absolute;
        left: 0;
        top: 0;
        margin: 15px;
        z-index: 3;
      }

      .special-tag {
        @include fonts.neue-haas-font(0.688rem, 400);
        float: right;
        position: absolute;
        right: 0;
        top: 0;
        margin: 15px;
        z-index: 3;
      }
    }
  }
}
