/* File used for general mixins: {@link https://sass-lang.com/documentation/at-rules/mixin} */

/* Mixin will hide DOM element from view but
  still accessible to screen readers (508 compliance)
 */
@mixin visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
