.widget-navigation-header {
  .horizontal-scroll-container {
    position: relative;
    overflow: hidden;

    display: flex;
    align-items: center;

    padding-left: 18px;
    padding-right: 27px;

    &.overflow {
      padding-left: 43px;

      .overflow-arrow.left {
        left: 14px;
      }
    }
  }
}
