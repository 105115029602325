@use "src/styles/fonts";

.change-password-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;

  background-color: #FFFFFF;

  // bottom margin to account for bottom navigation
  margin-bottom: 66px;

  @media (min-width: 768px) {
    top: 100px;
    margin-bottom: 0;
  }

  .change-password-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 50px 24px;

    .error-copy {
      text-align: center;
      margin-block: 0.5em;
    }

    .change-password-content-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      background-color: inherit;
      width: 100%;
      max-width: 375px;
      padding-inline-start: 0;

      .change-password-back-button {
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        align-self: start;
        height: 20px;
        margin-bottom: 20px;
      }

      .change-password-title {
        @include fonts.neue-haas-font(20px, 700);
        line-height: 24px;
        color: black;
      }

      .change-password-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        row-gap: 18px;

        .hook-password-input-container .hook-password-input-view-button {
          top: 12px;
        }

        fieldset {
          border: none;
          padding: 0;
          margin-inline: 0;
          width: 100%;

          .submit-button > .hook-button {
            border-radius: 6px;
            width: 100%;
            height: 48px;

            @include fonts.lato-font(1rem, 400);
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.38px;
          }
        }
      }
    }
  }
}
