@use "src/styles/fonts";

.text-only-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: black;
  min-height: 100%;
  width: 100%;

  .text-only-sections {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 540px;
  }

  .text-only-title {
    background-color: white;
    color: black;
    padding: 20px 24px 20px 24px;
    width: 100%;
    align-self: flex-start;

    h2 {
      @include fonts.neue-haas-font(1.25rem, 700);
      margin-block-start: 0;
      margin-block-end: 0;
    }

    p {
      @include fonts.neue-haas-font(1rem, 400);
      text-align: left;
      color: inherit;
      padding: 6px 0;
      word-break: break-word;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-top: 5px;
    }
  }

  .text-only-container {
    background-color: white;
    color: black;
    min-height: 100%;
    width: 100%;
    max-width: 540px;
    padding-bottom: 20px;

    .text-container {
      border-bottom: 1px solid lightgrey;
      align-items: center;
      margin-left: 23px;
      margin-right: 23px;
      color: black;
      padding-top: 26px;
      padding-bottom: 26px;

      .characteristic-container {
        margin-top: 6px;
      }

      .text-copy {
        display: flex;
        justify-content: space-between;
      }

      .special-tag + .text-copy {
        margin-top: 10px;
      }

      .text-price {
        @include fonts.open-sans-font(1rem, 400);
      }

      .text-name {
        @include fonts.neue-haas-font(1.063rem, 700);
        max-width: 50%;
      }

      .text-description {
        color: #333333;
        @include fonts.open-sans-font(0.875rem, 400);
        line-height: 18px;
        margin: 5px 0 4px 0;
        white-space: pre-wrap;
      }

      .special-tag {
        @include fonts.neue-haas-font(0.688rem, 400);
        padding: 4px 10px;
        min-height: initial;
        max-height: initial;
      }
    }

    .text-image-container {
      margin: 0 16px;
      color: black;
      padding: 12.5px 8px;
      min-height: 258.4px;

      display: grid;
      grid-template-columns: 1fr 148px;
      grid-template-rows: auto;
      grid-template-areas:
        "copy image";
      row-gap: 10px;
      grid-column-gap: 8px;
      align-items: flex-start;

      .special-tag {
        @include fonts.neue-haas-font(0.688rem, 400);
        padding: 4px 10px;
        min-height: initial;
        max-height: initial;
      }

      .text-copy {
        grid-area: copy;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        row-gap: 6px;

        .text-name {
          @include fonts.neue-haas-font(1.063rem, 700);
          line-height: 20px;
        }

        .text-price {
          line-height: 20px;
          @include fonts.open-sans-font(1rem, 400);
        }

        .text-description {
          color: #333333;
          line-height: 18px;
          @include fonts.open-sans-font(0.875rem, 400);
          white-space: pre-wrap;
        }
      }

      .image-container {
        grid-area: image;
        position: relative;

        .text-photo {
          height: 229px;
          width: 148px;
          border-radius: 8px;
          object-fit: cover;
          z-index: 1;
        }

        .image-gradient {
          grid-area: image;
          width: inherit;
          height: fit-content;
          z-index: 2;
          border-radius: 8px;

          &:after {
            content:'';
            position:absolute;
            left: 0;
            right: 0;
            bottom: 0;
            display: inline-block;
            height: 20%;
            z-index: 2; /* ensures the gradient is above the image but below the text */
            background: -moz-linear-gradient(transparent, rgba(0, 0, 0, 0.75)); /* FF3.6+ */
            background: -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.75)); /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(transparent, rgba(0, 0, 0, 0.75)); /* Opera 11.10+ */
            background: linear-gradient(transparent, rgba(0, 0, 0, 0.75));
            border-radius: 8px;
          }
        }

        .duplicate-icon,
        .play-icon {
          float: left;
          position: absolute;
          left: 0;
          top: 0;
          margin: 15px;
          z-index: 3;
        }
       }
    }
  }
}


$breakpoint-tablet: 768px;

@media (min-width: $breakpoint-tablet) {
  .text-image-container {
    grid-template-columns: 1fr 195px !important;
    padding: 12.5px 0;

    .text-copy {
      max-width: 287px;
    }

    .text-photo {
      border-radius: initial !important;
      width: 195px !important;
      height: 230px !important;
    }

    .image-gradient {
      border-radius: initial !important;
    }
  }
}
