.special-tag {
  min-height: 19px;
  padding: 2px 8px;
  font-size: inherit;
  line-height: 15px;
  font-style: inherit;
  color: #FFFFFF;
  text-align: center;
  font-weight: inherit;
  border-radius: 60px;
  word-break: keep-all;
  white-space: nowrap;
  font-family: inherit;
  letter-spacing: 0.25px;
}
