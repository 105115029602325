@use "src/styles/fonts";

.feedback-modal {
  background-color: black;
  z-index: 10;
  position: relative;
  max-width: 550px;

  [data-rsbs-header] {
    padding: 0;
  }

  [data-rsbs-scroll] {
    background-color: black;
  }

  [data-rsbs-overlay] {
    background-color: transparent !important;
  }

  &-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    padding: 23px 26px;
    row-gap: 7px;
    grid-template-areas:
      "text text"
      "textarea textarea"
      "input input"
      "button button";

    .feedback-copy {
      grid-area: text;
      @include fonts.open-sans-font(1rem, 300);
      line-height: 20px;
      color: #FFFFFF;
      width: 100%;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-bottom: 6px;
    }

    .feedback-text-area {
      grid-area: textarea;

      textarea {
        @include fonts.lato-font(0.75rem, 400);
        height: 180px;
        line-height: 24px;
        color: #4A4A4A;
        padding: 10px 0 0 14px
      }
    }


    .email-input {
      grid-area: input;

        input {
          @include fonts.lato-font(0.75rem, 400);
          height: 40px;
          line-height: 24px;
          color: #4A4A4A;
          padding-left: 14px;
        }
    }

    .feedback-button {
      grid-area: button;
      @include fonts.helvetica-neue-font(0.813rem, 700);
      background-color: #05944f;
      height: 40px;
      line-height: 16px;
      letter-spacing: 1px;
      border-radius: 4px;
      text-transform: uppercase;
      width: 100%;

      &:hover:not(:disabled) {
        filter: brightness(85%);
      }

      &:disabled {
        cursor: not-allowed;
        background: #69BE95;
      }
    }
  }
}
