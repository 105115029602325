@use "src/styles/fonts";

.options-accordion-container {
  &.is-widget {
    cursor: initial;
    pointer-events: none;
  }

  &:last-child {
    margin-block-end: 1em;
  }

  .is-open {
    .accordion-icon {
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
  }

  .accordion-icon {
    width: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .options-header-container {
    width: 340px;
    background-color: #E5E5E5;
    margin-top: 16px;
    padding: 12px 20px 12px 24px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .options-header-content {
      width: 100%;

      .options-header-title {
        @include fonts.helvetica-neue-font(1rem, 700);
        line-height: 24px;
        margin-block-start: 0;
        margin-block-end: 0;
      }

      .options-header-subtitle {
        @include fonts.helvetica-neue-font(0.75rem, 400);
        line-height: 20px;
        color: #808080;
      }
    }
  }

  .options-details-container {
    width: 340px;
    background-color: #FAFAFA;
    margin-top: 16px;
    @include fonts.helvetica-neue-font(1rem, 700);
    line-height: 24px;
    margin-block-start: 0;
    margin-block-end: 0;

    &:before {
      display: none;
    }

    .options-details-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      grid-template-areas:
        "info info"
        "description description";
      align-items: flex-end;
      column-gap: 5%;

      &.has-image {
        display: grid;
        grid-template-columns: 1fr 90px;
        grid-template-rows: 20px 20px 1fr;
        grid-template-areas:
        "info image"
        "info image"
        "description image";
        align-items: flex-start;
        column-gap: 5%;
        row-gap: 3px;

        .accordion-details-price {
          justify-self: flex-start;
        }

        .accordion-details-info {
          flex-direction: column;
          row-gap: 3px;
          justify-content: flex-start;
          align-items: flex-start;
        }
      }

      width: 100%;
      padding: 18px 16px 18px 40px;
      min-height: 48px;
      border-bottom: 1pt solid #e5e5e5;

      @mixin copy {
        @include fonts.helvetica-neue-font(0.938rem, 400);
        line-height: 21px;
        color: #000000;
        margin-block-start: 0;
        margin-block-end: 0;
      }

      .accordion-details-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        grid-area: info;
        column-gap: 5%;
        width: 100%;

        .accordion-details-copy {
          @include copy;
        }

        .accordion-details-price {
          @include copy;
          justify-self: flex-end;
        }
      }

      .accordion-details-description {
        grid-area: description;
        @include fonts.helvetica-neue-font(0.813rem, 400);
        line-height: 24px;
        color: #727272;
        align-self: flex-start;
        margin-block-start: 0;
        margin-block-end: 0;
        white-space: pre-wrap;
      }

      .accordion-details-image {
        grid-area: image;
        width: 90px;
        height: 120px;
        object-fit: cover;
        border-radius: 8px;
        align-self: flex-start;
      }
    }
  }
}
